import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class VehicleService {

    route = '/api/vehicle';

    constructor(private dataService: DataService) {
    };

    getAssemblyInfo(data: any, refresh: boolean) {
        return this.dataService.getData(this.route + '/assemblyinfo/' + data, refresh);
    }

    getTransportCategory(refresh: boolean) {
        return this.dataService.getData(this.route + '/transportcategory', refresh);
    }

    getAssembly(data: any, refresh: boolean) {
        return this.dataService.getData('/api/assembly/lookup/' + data, refresh);
    }

    saveTransportDetails(result: any) {
        return this.dataService.post(this.route + '/updateassemblyinfo', result).map(response => {
            this.dataService.clearRouteCache(this.route);
            return response;
        });
    }

    getLogData(vehicle: any, event: any, refresh: boolean) {
        return this.dataService.getData('/api/logbook/eventinfo' + '/' + vehicle + '/' + event, refresh);
    }

    getLogId(refresh: boolean) {
        return this.dataService.getData('/api/logbook/logtype', refresh);
    }

    saveLogDetails(result: any) {
        return this.dataService.post('/api/logbook', result).map(response => {
            this.dataService.clearRouteCache(this.route);
            return response;
        });
    }

    getLookUp(refresh: boolean) {
        return this.dataService.getData(this.route + '/lookup/', refresh);
    }

    getVehicleLookUp(data: any, refresh: boolean) {
        return this.dataService.getData(this.route + '/lookup/' + data, refresh);
    }

    getVehicleLookUpbyid(data: any, refresh: boolean) {
        return this.dataService.getData('/api/vehicle/vehiclelookup/' + data, refresh);
    }

    getVehicleList(data: any, refresh: boolean) {
        return this.dataService.getData(this.route + '/byclient/' + data, refresh);
    }

    getNotificationBlink(refresh: boolean) {
        return this.dataService.getData(this.route + '/checknotifications', refresh);
    }

    get(clientId: number, refresh: boolean) {
        return this.dataService.getData(this.route + '/byclient/' + clientId, refresh);
    }

    getbyId(data: any, refresh: boolean) {
        return this.dataService.getData(this.route + '/' + data.region + '/' + data.division + '/' + data.subdivision, refresh);
    }

    getTreeVehicle(clientId: number, refresh: boolean) {
        return this.dataService.getData('/api/vehiclegroup/vehiclegroups/' + clientId, refresh);
    }

    getById(id: number, refresh: boolean) {
        return this.dataService.getData(this.route + '/' + id, refresh);
    }

    save(result: any) {
        return this.dataService.post('/api/vehicle', result).map(response => {
            this.dataService.clearRouteCache(this.route);
            return response;
        });
    }

    deleteMenu(id: number, status: number) {
        return this.dataService.post('/api/vehicle/update/' + id + '/' + status, null).map(response => {
            this.dataService.clearCache();
            this.dataService.clearRouteCache(this.route);
            return response;
        });
    }

    getMakeLookUp(id: number, refresh: boolean) {
        return this.dataService.getData(this.route + '/makelookup/' + id, refresh);
    }

    getModelLookUpByMakeId(refresh: boolean, makeid) {
        return this.dataService.getData(this.route + '/modellookup/' + makeid, refresh);
    }

    getPermitLookUp(refresh: boolean) {
        return this.dataService.getData(this.route + '/permitlookup', refresh);
    }

    getInsuranceProviderLookUp(refresh: boolean) {
        return this.dataService.getData(this.route + '/insuranceproviderlookup', refresh);
    }

    getRouteLookup(id: number, refresh: boolean) {
        return this.dataService.getData('/api/vehicle/route/' + id, refresh);
    }

    downloadAttachment(id: number, refresh: boolean) {
        return this.dataService.getData('/api/vehicle/attachment/' + id, refresh);
    }

    getVehicleTypeLookup(refresh: boolean) {
        return this.dataService.getData('/api/lookup/vehicletype', refresh);
    }

    getVehiclegrouptype(refresh: boolean) {
        return this.dataService.getData('/api/lookup/vehiclegrouptype', refresh);
    }

    getZpv(data: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/vehicle/evmvehiclebyzpvassembly', data, refresh);
    }

    getVLookUp(refresh: boolean) {
        return this.dataService.getData('/api/vehicle/lookup', refresh);
    }

    getByvehicleId(vehicleId: number, refresh: boolean) {
        return this.dataService.getData('/api/vehicle/vehicledetail/' + vehicleId, refresh);
    }

    getVehiclelistByassemblyConstitutionId(vehicleId) {
        return this.dataService.getData('/api/vehicle/vehiclelist/' + vehicleId, true);
    }

    getVehicleTracking(id, start, refresh: boolean) {
        // return this.dataService.getData('/api/vehiclestatus/devicelog/' + 3315 + '/' + '2023-04-16', refresh)
        return this.dataService.getData('/api/vehiclestatus/devicelog/' + id + '/' + start, refresh)
    }

    getStoppagehistory(data: any, refresh: boolean) {
        return this.dataService.getData('/api/tripentry/stoppagehistory/' + data.vehicleId + '/' + data.start + '/' + data.end, refresh);
    }

    getVehicleLookUpall(refresh: boolean) {
        return this.dataService.getData('/api/lookup/vehicle', refresh);
    }

    getFence(vehicleId, refresh) {
        return this.dataService.getData('/api/fence/' + vehicleId, refresh)
    }

    getTripEvents(vehicleId, startTs, endTs, refresh) {
        return this.dataService.getData('/api/event/eventList/' + vehicleId + '/' + startTs + '/' + endTs, refresh)
    }

    deviceApprovalList(refresh) {
        return this.dataService.getData('/api/TechAssist/List', refresh);
    }

    deviceApprovalMappedList(refresh) {
        return this.dataService.getData('/api/TechAssist/MappedList', refresh);
    }

    ApprovalDeviceDetail(deviceId, refresh) {
        return this.dataService.getData('/api/TechAssist/DeviceDetail/' + deviceId, refresh);
    }

    deviceApprove(result) {
        return this.dataService.post('/api/TechAssist/Approve', result).map(response => {
            this.dataService.clearRouteCache(this.route);
            return response;
        });
    }

    updateDeviceInfo(result) {
        return this.dataService.post('/api/TechAssist/Update', result).map(response => {
            this.dataService.clearRouteCache(this.route);
            return response;
        });
    }

    saveteamDetails(data) {
        return this.dataService.post('/api/teaminfo', data).map(response => {
            this.dataService.clearRouteCache(this.route);
            return response;
        });
    }
    savecountingcentres(data) {
        return this.dataService.post('/api/cclocation', data).map(response => {
            this.dataService.clearRouteCache(this.route);
            return response;
        });
    }

    getcclocation(districtId:number,assemblyId:number,refresh) {
        return this.dataService.getData('/api/cclocation/' + districtId + '/' + assemblyId, refresh);
    }
    getteamsinfo(districtId:number,assemblyId:number,refresh) {
        return this.dataService.getData('/api/teaminfo/' + districtId + '/' + assemblyId, refresh);
    }
    getcclocationbyId(id:any,refresh) {
        return this.dataService.getData('/api/cclocation/'+id, refresh);
    }
    getteambyid(id:any,refresh) {
        return this.dataService.getData('/api/teaminfo/'+id, refresh);
    }
    cclocationDelete(id:any,refresh){
        return this.dataService.post('/api/cclocation/delete/'+id, refresh)
    }



}
