import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popupdialog',
  templateUrl: './popupdialog.component.html',
  styleUrls: ['./popupdialog.component.scss']
})
export class PopupdialogComponent implements OnInit {

  deviceInstallationDetails:any;
  imageUrl: any;
showImage: any;
  actionInfo: any;
  constructor(public dialogRef: MatDialogRef<PopupdialogComponent>,
    private modalService: NgbModal,

    @Inject(MAT_DIALOG_DATA) public dataRecieved: any) { 
      this.deviceInstallationDetails=dataRecieved.data;
      this.actionInfo=dataRecieved.actionInfo
      console.log(dataRecieved);
      
    }

  ngOnInit(): void {
  }
  openModelPopup(showImage: any, imageUrl) {
    this.imageUrl = imageUrl
    // this.modalService.open(showImage, { size: 'lg', centered: true, scrollable: true });
    this.modalService.open(showImage, { centered: true });
  }
  onCancel()
  {
    this.dialogRef.close();
  }
}
