import { Injectable } from '@angular/core';
import { DataService } from './data.service';
//import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
  })
export class ReportsService {

    getEventRoute = '/api/user/';

    constructor(private dataService: DataService) {
    };

    getProjectTimelyReport(query: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/projecttimely', query, refresh);
    }

    getEmployeeTimelyReport(query: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/employeetimely', query, refresh);
    }

    getTimesheetReport(query: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/timesheetreport', query, refresh);
    }

    forgotpasswordUser(user: any) {
        return this.dataService.post('/api/user/forgetpassword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    changepasswordUser(user: any) {
        return this.dataService.post('/api/user/ChangePassword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    newpaswordUser(user: any) {
        return this.dataService.post('/api/user/newpasword', user).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }
    downloadFuelReport(query: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/report/fuelreport', query, refresh);
    }

    downloadReport(query: any) {
        return this.dataService.getExternalData(query);
    }

    downloadTripReport(query: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/report/tripreport', query, refresh);
    }
    downloadStoppageTruckReport(query: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/report/stoppagetruckreport', query, refresh);
    }
    viewTripSimulationReport(query: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/report/tripsimulation', query, refresh);
    }
    /*  downloadReport(body: any, refresh: boolean) {
         return this.dataService.getDataWithParams('/ovcbeneficiary/exportaspdf', body, refresh);
     } */
    tripsimulate(query: any, refresh: boolean) {
        return this.dataService.post('/api/triplog/tripsimulate', query).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    tripreport(data: any) {
        return this.dataService.post('/api/report', data).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    trackingInfo(query: any, refresh: boolean) {
        return this.dataService.getDataWithParams('/api/triplog/trackinghistory/' + query.tripId + '/' + query.vehicleId, query, refresh);
    }

    saveFile = (blobContent: any, fileName: string, fileType: string) => {
        /*  if (fileName.endsWith(".xls")){
           fileName = fileName.replace(".xls", ".xlsx" )
         } */
        const byteCharacters = atob(blobContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });
        //FileSaver.saveAs(blob, fileName);
    }

    // getStationLookUp(refresh) {
    //     return this.dataService.getData('/api/district/statelookup', refresh);
    // }

    getTeamLookUp(refresh) {
        return this.dataService.getData('/api/vehicle/transportcategory', refresh);
    }
    gettransportcategories(refresh) {
        return this.dataService.getData('/api/lookup/transportcategorieslookup', refresh);
    }

    getShiftLookUp(refresh) {
        return this.dataService.getData('/api/lookup/shifts', refresh);
    }


    getDistrictListbyId(refresh: boolean, data) {
        return this.dataService.getDataWithParams('/api/district/list', data, refresh);
    }

    getAssemblyListbyId(refresh: boolean, data) {
        return this.dataService.getDataWithParams('/api/assembly/list', data, refresh);
    }
    getVehicleList(data:any, refresh: boolean){
        return this.dataService.getDataWithParams('/api/vehicle/lookup', data, refresh);
    }
}
