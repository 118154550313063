import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()

export class NavigationService {
  constructor(private router: Router) {

  }
  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }
  goToDashboardgrid() {
    this.router.navigate(['/Dashboard']);
  }
  goToRealTimeTracking() {
    this.router.navigate(['/realtimetracking']);
  }

  goToLogin() {
   // this.router.navigate(['/login']);
    this.router.navigate(['/auth/login']);
  }

  goToUnAuthorized() {
    this.router.navigate(['/unauthorized']);
  }

  goToSessionTimedOut() {
    this.router.navigate(['/sessiontimedout']);
  }

  isOnLoginScreen(): boolean {
    return this.router.url === '/login';
  }

  goToLiveTrack(id: number) {
    this.router.navigate(['/livetrack/' + id]);
  }
  goToLiveTrackOverview() {
    this.router.navigate(['/livetrack']);
  }

  goToVehicle(id: number, actionInfo: number) {
    this.router.navigate(['/vehicles/' + id, actionInfo]);
  }

  goToDriver(id: number, actionInfo: number) {
    this.router.navigate(['/drivers/' + id, actionInfo]);
  }
  goToFuelLog(id: number, actionInfo: number) {
    this.router.navigate(['/fuellog/' + id, actionInfo]);
  }

  goToUser(id: number, actionInfo: number) {
    this.router.navigate(['/users/' + id, actionInfo]);
  }
  goToProfile(id: number, actionInfo: number) {
    this.router.navigate(['/profile/' + id, actionInfo]);
  }
  goToVehicleGroup(id: number, actionInfo: number) {
    this.router.navigate(['/vehiclegroups/' + id, actionInfo]);
  }

  goToServiceSchedule(id: number, actionInfo: number) {
    this.router.navigate(['/serviceschedules/' + id, actionInfo]);
  }

  goToInsurance(id) {
    this.router.navigate(['/insurancepopup/' + id]);
  }
  goToServiceLog(id: number, actionInfo: number, pagetype:string, status:number) {
    this.router.navigate(['/servicelog/' + id, actionInfo, pagetype, status]);
  }
  goToDevice(id: number, actionInfo: number) {
    this.router.navigate(['/devices/' + id, actionInfo]);
  }
  goToAddDevice(id: number, actionInfo: number) {
    this.router.navigate(['/adddevices/' + id, actionInfo]);
  }
  goToProvider(id: number, actionInfo: number) {
    this.router.navigate(['/providers/' + id, actionInfo]);
  }
  goToTripsimulation(data: any) {
    this.router.navigate(['/tripsimulationreport/' + data]);
  }
  goToTripsimulationwithpagetype(data: any, pageType:number) {
    this.router.navigate(['/tripsimulationreport/' + data, pageType]);
  }
  goToRegion(id: number, actionInfo: number) {
    this.router.navigate(['/regions/' + id, actionInfo]);
  }
  addnewteams(id: number, actionInfo: number) {
    this.router.navigate(['/evmteam/' + id, actionInfo]);
  }
  addgeofence(id: number, actionInfo: number) {
    this.router.navigate(['/geofence/' + id, actionInfo]);
  }
  addnewcountingcenter(id: number, actionInfo: number) {
    this.router.navigate(['/countingcentre/' + id, actionInfo]);
  }
  goToDivision(id: number, actionInfo: number) {
    this.router.navigate(['/divisions/' + id, actionInfo]);
  }
  goToSubDivision(id: number, actionInfo: number) {
    this.router.navigate(['/subdivisions/' + id, actionInfo]);
  }
  goToWorkshops(id: number, actionInfo: number) {
    this.router.navigate(['/workshops/' + id, actionInfo]);
  }
  goToImplement(id: number, actionInfo: number) {
    this.router.navigate(['/implements/' + id, actionInfo]);
  }
  goToTarrif(id: number, actionInfo: number) {
    this.router.navigate(['/tariffs/' + id, actionInfo]);
  }
  goToTripEntry(id: number, actionInfo: number, approveTrue:boolean, pageType:number) {
    this.router.navigate(['/tripentries/' + id, actionInfo, approveTrue, pageType]);
  }
  goToTarget(id: number, actionInfo: number, pageType:number) {
    this.router.navigate(['/targets/' + id, actionInfo, pageType]);
  }
  goToexpenseLogs(id: number, actionInfo: number) {
    this.router.navigate(['/expenselogs/' + id, actionInfo]);
  }
  goToConsumables(id: number, actionInfo: number) {
    this.router.navigate(['/consumables/' + id, actionInfo]);
  }
  goToConsumableentries(id: number, actionInfo: number, pageType:number) {
    this.router.navigate(['/consumableentries/' + id, actionInfo, pageType]);
  }
  goToexpenseentries(id: number, actionInfo: number){
    this.router.navigate(['/expenseentries/' + id, actionInfo]);
  }
  goToMakes(id: number, actionInfo: number) {
    this.router.navigate(['/makes/' + id, actionInfo]);
  }
  goToMoels(id: number, actionInfo: number) {
    this.router.navigate(['/models/' + id, actionInfo]);
  }

  goToEvmMapping(data: any, actionInfo: number) {
    this.router.navigate(['/EvmVehicleMapping/' + data, actionInfo]);
  }

  goTomapdebug(data: any) {
    this.router.navigate(['/mapdebug/' + data]);
  }

  goToSingleLiveTrack(id: number, transportid:number, pageType: number) {
    this.router.navigate(['/livetrack/', id,  transportid, pageType]);
  }
  goToLiveTrackfromEvm(pageType: number) {
    this.router.navigate(['/livetrack/',  pageType]);
  }

  goToDeviceInstallation() {
    this.router.navigate(['/deviceinstallation']);
  }

  goToEvmDashboard() {
    this.router.navigate(['EVMDashboard'])
  }
  goTonewPackingList(id, actionInfo) {
    this.router.navigate(['/packingentry/' + id, actionInfo]);
  }


  goToDeviceStatus() {
    this.router.navigate(['/packingentry/'+'devicestatus' ]);
  }

  goToPackingEntryGrid(){
    this.router.navigate(['/packingentry/'])
  }

  goToReturnForm(){
    this.router.navigate(['/packingentry/'+'returnform' ]);
  }
  goToPackingBulkLoad(){
    this.router.navigate(['/packingentry/Bulkload' ]);
  }
  goToadduser(id, actionInfo){
    this.router.navigate(['/user/' + id, actionInfo]);
  }
  goTodeviceinstallationView(id,actionInfo) {
    this.router.navigate(['/deviceinstallation/' + id,actionInfo]);
  }
  gotomanagment()
  {
    this.router.navigate(['/userManagemnt']);
  }
  gotocomplaint()
  {
    this.router.navigate(['/complaint']);
  }
  gotounified()
  {
    this.router.navigate(['/unified']);
  }
}
