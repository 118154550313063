export enum RoleType {
  Root = 1,
  Admin = 2,
  ZoneAdmin = 3,
  DistrictAdmin = 4,
  ACAdmin = 5,
  Supervisor = 6,
  Operator = 7,
  DataManagement = 8,
  ZoneControlCenter = 9,
  DistrictControlCenter = 10,
  AssemblyControlCenter = 11,
  DEO = 12,
  DCEO = 13,
}
