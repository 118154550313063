import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertService } from 'src/app/services/alert.service';
import { GlobalProvider } from 'src/app/services/global';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-reportpopup',
  templateUrl: './reportpopup.component.html',
  styleUrls: ['./reportpopup.component.scss']
})
export class ReportpopupComponent implements OnInit {
  loading = true;
  titletxt: any;
  pdfSource: any;
  src1: any;
  ispreview: any;
  ReportType: number;
  constructor( private alertService: AlertService,
    private reportService :ReportsService,
    public sanitizer: DomSanitizer,public loaderOFF: GlobalProvider,
    @Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<ReportpopupComponent>,

    ) { 
      this.loaderOFF.loaderShow = true;
  }

  ngOnInit(): void {
    this.data.downloadType = 4;
    this.ispreview = this.data.ispreview;
    this.ReportType = this.data.ReportType;

    this.reportService.tripreport(this.data).subscribe(result => {
      if (result) {
        
        const decodedStringAtoB = decodeURIComponent(escape(window.atob(result)));
        this.src1 = this.sanitizer.bypassSecurityTrustHtml(decodedStringAtoB);
        this.loaderOFF.loaderShow = false;
      }
      else{
        this.loaderOFF.loaderShow = false;
      }
      
    },err=>{
      this.loaderOFF.loaderShow = false;
    });
  }

  downloadword() {
    this.loaderOFF.loaderShow = true;
    const worddata = this.data;
    worddata.downloadType = 3;
    this.reportService.tripreport(worddata).subscribe(result => {
      if (result) {
        const linkSource = 'data:application/msword;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.titletxt + '.doc';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.loaderOFF.loaderShow = false;
      }
      else{
        this.loaderOFF.loaderShow = false;
      }
      
    },err=>{
      this.loaderOFF.loaderShow = false;
    });  }
  downloadpdf() {
    debugger;
    this.loaderOFF.loaderShow = true;
    const pdfdata = this.data;
    pdfdata.downloadType = 1;
    this.reportService.tripreport(pdfdata).subscribe(result => {
      if (result) {
        this.pdfSource = result;
        const linkSource = 'data:application/pdf;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.titletxt + '.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.loaderOFF.loaderShow = false;
      }
      else{
        this.loaderOFF.loaderShow = false;
      }
      
    },err=>{
      this.loaderOFF.loaderShow = false;
    });  }
  downloadexcel() {
    this.loaderOFF.loaderShow = true;
    const printdata = this.data;
    printdata.downloadType = 2;
    this.reportService.tripreport(printdata).subscribe(result => {
      if (result) {
        debugger
        const linkSource = 'data:application/vnd.ms-excel;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.titletxt + '.xls';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        this.loaderOFF.loaderShow = false;
      }
      else{
        this.loaderOFF.loaderShow = false;
      }
      
    },err=>{
      this.loaderOFF.loaderShow = false;
    });  }
  onCancel() {
    this.dialogRef.close();
  }
  onSubmit(id: number) {
    this.alertService.success(this.titletxt + " Submitted successfully")
    this.onCancel();
  }
}
