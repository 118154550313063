import { Component, OnInit } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { SharedService } from 'src/app/services/sharedService';
@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  isLoading: boolean;
  isFullScreen: string = "N";
  isDarkmode:boolean=false;


  constructor(private router: Router, private sharedService: SharedService) {
    // this.sharedService.emitChangeSourceDarkMode$.subscribe((res: any) => {
     
    //   this.isDarkmode = res.isDarkMode;
    // });

    // Spinner for lazyload modules
    router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
        
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
      if(router.url!='/dashboard'&& router.url!='/livestreamingview' && router.url!='/cameradetails'){
        this.Testdark()

        
      }
      else{
        let darkThemeclr: any = localStorage.getItem('isdarktheme')
        darkThemeclr = darkThemeclr == 'false' ? false : true;
        (document.getElementsByClassName("navbar")[0] as HTMLElement).style.backgroundColor = darkThemeclr ? "#292929" : "white";
        (document.getElementsByClassName("page-content")[0] as HTMLElement).style.backgroundColor = darkThemeclr ? "#292929" : "white";
        (document.getElementsByClassName("sidebar-body")[0] as HTMLElement).style.backgroundColor = darkThemeclr ? "#292929" : "white";
        (document.getElementsByClassName("sidebar-header")[0] as HTMLElement).style.backgroundColor = darkThemeclr ? "#292929" : "white";
        (document.getElementsByClassName("footer")[0] as HTMLElement).style.backgroundColor = darkThemeclr ? "#292929" : "white";

      }
    });
    if (localStorage.getItem('isFullScreen') != undefined) {
      this.isFullScreen = localStorage.getItem('isFullScreen');
    }

    this.sharedService.emitChangeSourceMenu$.subscribe((res: any) => {
      this.isFullScreen = localStorage.getItem('isFullScreen');
      localStorage.setItem('isFullScreen','Y');
      if (!document.fullscreenElement) {
        // Enter fullscreen mode
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        }
      } else {
        // Exit fullscreen mode
        if (document.exitFullscreen) {
          document.exitFullscreen();
        }
      }
    //  this.sharedService.loadMenu1(this.isFullScreen);
    });
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
   
  }
Testdark()
{
  // let darkThemeclr: any = localStorage.getItem('isdarktheme')
  // darkThemeclr = darkThemeclr == 'false' ? false : true;
  (document.getElementsByClassName("navbar")[0] as HTMLElement).style.backgroundColor =  "white";
  (document.getElementsByClassName("page-content")[0] as HTMLElement).style.backgroundColor = "white";
  (document.getElementsByClassName("sidebar-body")[0] as HTMLElement).style.backgroundColor ="white";
  (document.getElementsByClassName("sidebar-header")[0] as HTMLElement).style.backgroundColor ="white";
  (document.getElementsByClassName("footer")[0] as HTMLElement).style.backgroundColor = "white";

  localStorage.setItem('isdarktheme','false')
}
}
