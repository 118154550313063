import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BaseComponent } from './base/base.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';

import { ContentAnimateDirective } from '../../core/content-animate/content-animate.directive';

import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { FeahterIconModule } from '../../core/feather-icon/feather-icon.module';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { DirectivesModule } from './directives/directives.module';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { TranslateModule } from '@ngx-translate/core';
import { RegionService } from 'src/app/services/region.service';
import { DivisionService } from 'src/app/services/division.service';
import { SubDivisionService } from 'src/app/services/subdivision.service';
import { NotifyService } from 'src/app/services/notifyService';
import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatCheckboxModule } from '@angular/material/checkbox';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};


@NgModule({
  declarations: [BaseComponent, NavbarComponent, SidebarComponent, FooterComponent, ContentAnimateDirective, ChangepasswordComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    SelectDropDownModule,
    PerfectScrollbarModule,
    FeahterIconModule,
    DirectivesModule,
    MatDialogModule,
    TranslateModule,
    ReactiveFormsModule,
    MatSelectModule, 
    MatSelectFilterModule,
    MatCheckboxModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    UserService,
    RegionService,
    DivisionService,
    SubDivisionService,
    NotifyService,
  ]
})
export class LayoutModule { }
