import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fileviewer',
  templateUrl: './fileviewer.component.html',
  styleUrls: ['./fileviewer.component.scss']
})
export class FileviewerComponent implements OnInit {

  files = [];
  imageSrc: '';

  ngOnInit() {
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<FileviewerComponent>) {
    if (data) {
      this.imageSrc = data.url;
    }
    // this.dialogRef.updateSize('300vw', '300vw')
  }


  onCancel() {
    this.dialogRef.close(true);
  }

}
