import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ThirdPartyService } from 'src/app/services/thirdparty.service';
import { API_ENDPOINTS } from 'src/enum/api-endpoint';
import { RESPONSE_CODE } from 'src/enum/responseCode';
import { Subscription, interval, timer, fromEvent, Observable, } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { Navigationlevel } from 'src/enum/navigationlevel.enum';
import { UserSessionService } from 'src/app/services/usersession.service';
import { RoleTypeDesc } from 'src/enum/roletypeDesc';
import { DashboardService } from 'src/app/services/dashboard.service';
import { GlobalProvider } from 'src/app/services/global';
import { RoleType } from 'src/enum/roletype';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  data: any = [];
  isDarkmode:boolean=false;
  districtList: any = [];
  assemblyList: any = [];
  boothList: any = [];
  district_ID: number = 0;
  assembly_ID: number = 0;
  liveList: boolean = false;
  webDataID: number = 0;
  pageNumber: number = 1;
  dashboardCardText: string = environment.dashboardCardText;
  colorCodes: any = ["#264D59",
    "#43978D",
    "gray",
    "#3f67a7",
    "#D46C4E"]
  //colorCodes:any=["#455054","#308695","#D45769","#E69D45","#D4CFC9"]
  cardList: any = [{
    count: 0,
    key: "Total Camera",
    img: "../assets/dashboard/TotalCamera.png",
    colorCode: this.colorCodes[0]
  },
  {
    count: 0,
    key: "Online Camera",
    img: "../assets/dashboard/OnlineCamera.png",
    colorCode: this.colorCodes[1]
  },
  {
    count: 0,
    key: "Offline Camera",
    img: "../assets/dashboard/OfflineCamera.png",
    colorCode: this.colorCodes[2]
  },
  {
    count: 0,
    key: this.dashboardCardText,
    img: "../assets/dashboard/OnceLive.png",
    colorCode: this.colorCodes[3]
  },
  {
    count: 0,
    key: "Last 60 Mins Online",
    img: "../assets/dashboard/Hours.png",
    colorCode: this.colorCodes[4]
  }];
  private intervalTime = 10000;
  private apiSubscription: Subscription;
  currentViewData: any = [];
  chartsData: any = { assembly_ID: 0, list: [] };
  cardDetailsData: any = {};
  i: number = 0;
  isViewCardDetails: boolean = false;
  assemblyListDashboardData: any = [];
  districtDashboardData: any = [];
  districtVal: any = 0;
  assemblyVal: any = 0;
  dynamicData: any = {};
  label: any = [];
  timeoutId: any;
  project: any = [];
  Navigationlevel: any = Navigationlevel;
  countDown: Subscription;
  counter = 31;
  zoneId: number = 0;
  stateId: number = 0;
  isDistrictDisabled: boolean = false;
  isAssemblyDisabled: boolean = false;
  roleType: string = '';
  isHoursData: string = 'N';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;
  isFirstTimeLoad: boolean = true;
  roleTypeId: number = 0;
  RoleType: any = RoleType;
  isListView: boolean = false;
  isBackBtn: boolean = false;
  cameraLocationId: any = 0;
  cameraLocationList = [{ key: 0, value: "ALL" },
  { key: 1, value: "IN" },
  { key: 2, value: "OUT" }];
  cameraStatusList = [
    { key: 0, value: "ALL" },
    //{ key: 1, value: "CRT-VUL" },
    //  { key: 2, value: "LWE" },
    // { key: 3, value: "Normal" },
    //  { key: 4, value: "SB" },
    //  { key: 5, value: "Others" }
  ];
  cameraStatusId: any = 0;
  isEnable: number = 1;
  col: string = 'col-sm-12 col-md-6 col-lg-2 ml-auto mb-1';
  col2: string = 'col-sm-12 col-md-6 col-lg-2 mb-1';
  col1: string = 'col-sm-12 col-md-6 col-lg-7 text-right';
  labelText: string = 'PS Category';
  constructor(private thirdPartyService: ThirdPartyService, private sharedService: SharedService,
    private userSessionService: UserSessionService, private dashboardService: DashboardService,
    public loaderOFF: GlobalProvider) {
    try {
      this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));
      this.district_ID = this.dynamicData.parliamentConstitutionId;
      this.assembly_ID = this.dynamicData.assemblyConstitutionId;
      let getRoleName = this.userSessionService.roleName();
      this.roleType = getRoleName.toUpperCase();
      this.roleTypeId = this.userSessionService.roleId();
      // this.dynamicData.isCamLocationFilter = this.dynamicData?.isCamLocationFilter == 1 ? 0 : 1;
      if (this.dynamicData?.isCamLocationFilter == this.isEnable && this.dynamicData?.isBoothCategory == this.isEnable) {
        this.col = 'col-sm-12 col-md-6 col-lg-2 ml-auto mb-1';
        this.col2 = 'col-sm-12 col-md-6 col-lg-2 mb-1';
        this.col1 = 'col-sm-12 col-md-3 col-lg-2 text-right';
      } else if (this.dynamicData?.isCamLocationFilter == this.isEnable || this.dynamicData?.isBoothCategory == this.isEnable) {
        this.col = 'col-sm-12 col-md-6 col-lg-2 ml-auto mb-1';
        this.col2 = 'col-sm-12 col-md-6 col-lg-4 mb-1';
        this.col1 = 'col-sm-12 col-md-3 col-lg-3 text-right';
      }
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');
      this.subscriptions.push(this.onlineEvent.subscribe(e => {
        this.connectionStatusMessage = 'Back to online';
        this.connectionStatus = 'online';
      }));
      this.subscriptions.push(this.offlineEvent.subscribe(e => {
        this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
        this.connectionStatus = 'offline';
      }));
      if (environment.apiBaseUrl.includes("klccvapi")) {
        this.labelText = 'Centre Type';
        this.cameraStatusList = [{ key: 0, value: "ALL" },
        { key: 1, value: "Control Centre" },
        { key: 2, value: "Collection centre" }
        ];
        this.cameraStatusId = 2;
      }
    } catch (error) {

    }
    this.loaderOFF.loaderShow = true;
    let darkThemeclr: any = localStorage.getItem('isdarktheme')
    darkThemeclr = darkThemeclr == 'false' ? false : true;
   this.isDarkmode= darkThemeclr;
  }

  ngOnInit(): void {
    try {
      localStorage.setItem('cameraLocationId', this.cameraLocationId);
      localStorage.setItem('cameraStatusId', this.cameraStatusId);
      localStorage.setItem('unifieddashboard', 'N')
      let getbackfromlivestreaming = JSON.parse(localStorage.getItem('backfromlivestreaming'))
      let parliamentConstitutionId = 0;
      if (getbackfromlivestreaming != null && getbackfromlivestreaming.isBack == 'N') {
        let setData = {
          district_ID: this.dynamicData?.parliamentConstitutionId,
          assembly_ID: this.dynamicData?.assemblyConstitutionId,
          currentView: Navigationlevel.level_01,
          breadcrumb: '',
          pageTitle: this.dynamicData?.label_01
        }
        let backfromlivestreaming = {
          district_ID: 0,
          isBack: 'N',
          currentData: setData
        }
        localStorage.setItem('backfromlivestreaming', JSON.stringify(backfromlivestreaming))


        localStorage.setItem('currentData', JSON.stringify(setData))
      }
      if (getbackfromlivestreaming != null && getbackfromlivestreaming.isBack == 'Y') {
        parliamentConstitutionId =  getbackfromlivestreaming.currentData.district_ID
      }
      let setData = {
        district_ID: this.district_ID,
        assembly_ID: this.assembly_ID,
        currentView: Navigationlevel.level_01,
        breadcrumb: '',
        pageTitle: this.dynamicData?.label_01,
        parliamentConstitutionId: parliamentConstitutionId
      }
      localStorage.setItem('currentData', JSON.stringify(setData))
      this.currentViewData = JSON.parse(localStorage.getItem('currentData'))
      const getHours = new Date().getHours();
      localStorage.setItem('currentHour', getHours.toString());
      localStorage.setItem('hoursData', null);
      // this.getDashboradList();
      this.getDropdownList();
      this.startApiCalls();
      this.districtList.unshift({ District_ID: 0, District_name: "ALL" });
      this.isListView = false;
      this.isBackBtn = false
      let event = { checked: false }
      this.openListView(event);
      this.getEnumList();
    } catch (error) {

    }

  }

  ngOnDestroy(): void {


    clearTimeout(this.timeoutId);
    this.stopApiCalls();
  }


  onDistrictChange(e) {
    this.district_ID = e;
    this.assembly_ID = 0;
    this.isHoursData = 'N';
    this.dashboardService.getDistrictIdByAssemblyList(this.district_ID, true).subscribe(res => {
      this.assemblyList = res;
      this.assemblyList.unshift({ key: 0, regionalValue: "ALL" });
      this.getDashboradList();
    })
    // this.getDropdownList();

  }
  onCameraLocationChange() {
    localStorage.setItem('cameraLocationId', this.cameraLocationId);
    localStorage.setItem('cameraStatusId', this.cameraStatusId)
    this.sharedService.emitChange(this.cameraLocationId);
    this.getDashboradList();
  }
  setSelectedIdByDashboard() {
    try {

      let localData = this.data;
      let res = { TotalCam: 0, OnlineCam: 0, OfflineCam: 0, OnceliveCam: 0, LasthourCam: 0 };
      let filterData = [];
      let dynamicArrayList = this.districtList;
      let filterList = [];
      let filterType = 'DistrictID';
      if (this.district_ID > 0 && this.assembly_ID == 0) {
        let districtIdByFilterData = dynamicArrayList;
        dynamicArrayList = districtIdByFilterData.filter(j => j.key == this.district_ID);
        filterType = 'DistrictID';
      }
      else if (this.district_ID > 0 && this.assembly_ID > 0) {
        dynamicArrayList = this.assemblyList;
        let assemblyIdByFilterData = dynamicArrayList;
        dynamicArrayList = assemblyIdByFilterData.filter(j => j.key == this.assembly_ID);
        filterType = 'AssemblyID';
      }


      dynamicArrayList.forEach(element => {
        filterList = localData.dataList.filter(j => j[filterType] == element.key);
        if (filterList.length > 0) {
          filterData.push(...filterList)
        }
      });

      filterData.forEach(element => {
        res['TotalCam'] += element.TotalCam;
        res['OnlineCam'] += element.OnlineCam;
        res['OfflineCam'] += element.OfflineCam;
        res['OnceliveCam'] += element.OnceliveCam;
        res['LasthourCam'] += element.LasthourCam;
      });
      res['dataList'] = filterData;

      this.cardList = [];
      this.cardList.push({
        count: res['TotalCam'],
        key: "Total Camera",
        img: "../assets/dashboard/TotalCamera.png",
        colorCode: this.colorCodes[0]
      },
        {
          count: res['OnlineCam'],
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: res['OfflineCam'],
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: res['OnceliveCam'],
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: res['LasthourCam'],
          key: "Last 60 Mins Online",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }
      )

      let currentHour = localStorage.getItem('currentHour');

      if (this.isHoursData == 'N') {
        let payload = {};
        let URL = API_ENDPOINTS.CamHourList;
        payload = {
          "WebUSER": this.dynamicData?.webUser,
          "WebproID": this.dynamicData?.webProId,
          "District_ID": this.district_ID,
          "Assembly_ID": this.assembly_ID,
          "WebDataID": 0,
          "psFlag": parseInt(this.cameraLocationId),
          "cvlFlag": parseInt(this.cameraStatusId),
          // "LUFlag":this.dynamicData?.LUFlag
        }
        if (this.isFirstTimeLoad) {
          this.isFirstTimeLoad = false;
          this.loaderOFF.loaderShow = true;
        }

        this.thirdPartyService.postMethod(URL, payload).subscribe(response => {
          if (response.API_CODE == RESPONSE_CODE.SUCCESS) {
            localStorage.setItem('hoursData', JSON.stringify(response.HourList));
            this.isHoursData = 'Y'
            res['HourList'] = response.HourList;
            this.chartsData = {
              assembly_ID: this.assembly_ID,
              list: res
            }
            this.loaderOFF.loaderShow = false;
          } else {
            this.loaderOFF.loaderShow = false;
            console.log('Dashborad API Response Code => ' + response.API_CODE + ' & Msg => ' + response.API_MSG);
            res['HourList'] = [];
            this.chartsData = {
              assembly_ID: this.assembly_ID,
              list: res
            }
          }
        },
          err => {
            this.loaderOFF.loaderShow = false;
            res['HourList'] = [];
            this.chartsData = {
              assembly_ID: this.assembly_ID,
              list: res
            }
          });
      } else {
        let getHours = new Date().getHours()
        if (getHours == parseInt(currentHour)) {
          res['HourList'] = JSON.parse(localStorage.getItem('hoursData'));
          this.chartsData = {
            assembly_ID: this.assembly_ID,
            list: res
          }
        } else {
          this.isHoursData = 'N';
          let payload = {};
          let URL = API_ENDPOINTS.CamHourList;
          payload = {
            "WebUSER": this.dynamicData?.webUser,
            "WebproID": this.dynamicData?.webProId,
            "District_ID": this.district_ID,
            "Assembly_ID": this.assembly_ID,
            "WebDataID": 0,
            "psFlag": parseInt(this.cameraLocationId),
            "cvlFlag": parseInt(this.cameraStatusId),
            //"LUFlag":this.dynamicData?.LUFlag
          }
          if (this.isFirstTimeLoad) {
            this.isFirstTimeLoad = false;
            this.loaderOFF.loaderShow = true;
          }
          this.thirdPartyService.postMethod(URL, payload).subscribe(response => {
            if (response.API_CODE == RESPONSE_CODE.SUCCESS) {
              localStorage.setItem('hoursData', JSON.stringify(response.HourList));
              this.isHoursData = 'Y';
              this.loaderOFF.loaderShow = false;
              const getHours = new Date().getHours();
              localStorage.setItem('currentHour', getHours.toString());
              res['HourList'] = response.HourList;
              this.chartsData = {
                assembly_ID: this.assembly_ID,
                list: res
              }
            } else {
              this.loaderOFF.loaderShow = false;
              res['HourList'] = [];
              this.chartsData = {
                assembly_ID: this.assembly_ID,
                list: res
              }
              console.log('Dashborad API Response Code => ' + response.API_CODE + ' & Msg => ' + response.API_MSG);
            }
          },
            err => {
              res['HourList'] = [];
              this.chartsData = {
                assembly_ID: this.assembly_ID,
                list: res
              }
              this.loaderOFF.loaderShow = false;
            });
        }

      }
      this.connectionStatus = 'online';
      let getbackfromlivestreaming = JSON.parse(localStorage.getItem('backfromlivestreaming'))
      if (getbackfromlivestreaming != null && getbackfromlivestreaming.isBack == 'Y') {
        this.assemblyUserDashboard();
      }
    } catch (error) {

    }
  }

  onAssemblyChange(e) {
    this.assembly_ID = e;
    this.isHoursData = 'N';
    this.getDashboradList();
  }
  openListView(event) {
    if (event.checked) {
      this.isBackBtn = true;
      this.isHoursData = 'N';
      let setData = {
        district_ID: this.dynamicData.parliamentConstitutionId,
        assembly_ID: this.dynamicData.assemblyConstitutionId,
        currentView: Navigationlevel.level_01,
        breadcrumb: '',
        pageTitle: this.dynamicData?.label_01
      }
      this.cardDetailsData = {};
      this.currentViewData = setData;
      localStorage.setItem('currentData', JSON.stringify(setData))
      this.isViewCardDetails = !this.isViewCardDetails;
      this.district_ID = this.dynamicData.parliamentConstitutionId;
      this.assembly_ID = this.dynamicData.assemblyConstitutionId;
      this.getDashboradList();
      this.counter = 30;
      this.startApiCalls();
    } else {
      let setData = {};
      let district_ID = this.dynamicData.parliamentConstitutionId;
      let backfromlivestreaming = JSON.parse(localStorage.getItem('backfromlivestreaming'));
      if (backfromlivestreaming != null && backfromlivestreaming != undefined && backfromlivestreaming != '') {
        if (backfromlivestreaming.isBack == 'Y')
          district_ID = backfromlivestreaming.district_ID;
        setData = backfromlivestreaming.currentData;
      } else {
        setData = {
          district_ID: this.district_ID,
          assembly_ID: this.assembly_ID,
          currentView: Navigationlevel.level_01,
          breadcrumb: '',
          pageTitle: this.dynamicData?.label_01
        }
      }
      this.isBackBtn = false;

      this.cardDetailsData = {};
      localStorage.setItem('currentData', JSON.stringify(setData));
      // localStorage.setItem('filterType','ALL');
      this.isViewCardDetails = !this.isViewCardDetails;
      this.counter = 30;
      //  this.stopApiCalls();
    }
  }
  private startApiCalls(): void {
    this.countDown = timer(0, 1000).subscribe(() => {
      --this.counter;
      if (this.counter === 0) {
        this.counter = 30;
        if (this.currentViewData.currentView == Navigationlevel.level_02) {
          this.assemblyUserDashboard();
        } else {
          this.getDashboradList();
        }
      }
    });
    // this.apiSubscription = interval(this.intervalTime).subscribe(() => {
    //   console.log('intervalTime', this.intervalTime)

    //   this.getDashboradList();
    // });
  }
  onMessageFromCardChild(message: string) {
    this.currentViewData = JSON.parse(localStorage.getItem('currentData'));
    this.isBackBtn = this.currentViewData.isBackBtn;
    if (this.currentViewData.currentView == Navigationlevel.level_02) {
      this.assemblyUserDashboard();
    } else {
      this.getDashboradList();
    }

  }

  assemblyUserDashboard() {
    let payload = {};
    let _this = this;
    let URL = '';
    payload = {
      "WebUSER": this.dynamicData?.webUser,
      "WebproID": this.dynamicData?.webProId,
      "DistrictID": this.currentViewData?.parliamentConstitutionId,
      "psFlag": parseInt(this.cameraLocationId),
      "cvlFlag": parseInt(this.cameraStatusId),
      // "LUFlag":this.dynamicData?.LUFlag
    }
    URL = API_ENDPOINTS.AssemblyUserDashboard;
    _this.thirdPartyService.postMethod(URL, payload).subscribe(res => {
      if (res.API_CODE == RESPONSE_CODE.SUCCESS) {
        try {
          let data = { TotalCam: 0, OnlineCam: 0, OfflineCam: 0, OnceliveCam: 0, LasthourCam: 0 };
          res.dataList.forEach(element => {
            data['TotalCam'] += element.TotalCam;
            data['OnlineCam'] += element.OnlineCam;
            data['OfflineCam'] += element.OfflineCam;
            data['OnceliveCam'] += element.OnceliveCam;
            data['LasthourCam'] += element.LasthourCam;
          });

          this.cardList = [];
          this.cardList.push({
            count: data['TotalCam'],
            key: "Total Camera",
            img: "../assets/dashboard/TotalCamera.png",
            colorCode: this.colorCodes[0]
          },
            {
              count: data['OnlineCam'],
              key: "Online Camera",
              img: "../assets/dashboard/OnlineCamera.png",
              colorCode: this.colorCodes[1]
            },
            {
              count: data['OfflineCam'],
              key: "Offline Camera",
              img: "../assets/dashboard/OfflineCamera.png",
              colorCode: this.colorCodes[2]
            },
            {
              count: data['OnceliveCam'],
              key: this.dashboardCardText,
              img: "../assets/dashboard/OnceLive.png",
              colorCode: this.colorCodes[3]
            },
            {
              count: data['LasthourCam'],
              key: "Last 60 Mins Online",
              img: "../assets/dashboard/Hours.png",
              colorCode: this.colorCodes[4]
            }
          )

        }
        catch (error) {

        }
      } else {

        console.log('AssemblyU Dashborad API Response Code => ' + res.API_CODE + ' & Msg => ' + res.API_MSG);
      }
    });

  }

  private stopApiCalls(): void {
    if (this.countDown) {
      this.countDown.unsubscribe();
      clearTimeout(this.timeoutId);
      console.log('2')
    }
  }

  getDashboradList() {
    let _this = this;
    let payload = {};
    let URL = API_ENDPOINTS.UserDashboard;
    payload = {
      "WebUSER": this.dynamicData?.webUser,
      "WebproID": this.dynamicData?.webProId,
      "psFlag": parseInt(_this.cameraLocationId),
      "cvlFlag": parseInt(this.cameraStatusId),
      //"LUFlag":this.dynamicData?.LUFlag
    }

    if (this.assembly_ID > 0) {
      payload = {
        "WebUSER": this.dynamicData?.webUser,
        "WebproID": this.dynamicData?.webProId,
        "DistrictID": this.district_ID,
        "psFlag": parseInt(_this.cameraLocationId),
        "cvlFlag": parseInt(this.cameraStatusId),
        //"LUFlag":this.dynamicData?.LUFlag
      }
      URL = API_ENDPOINTS.AssemblyUserDashboard;
    }

    this.thirdPartyService.postMethod(URL, payload).subscribe(res => {
      if (res.API_CODE == RESPONSE_CODE.SUCCESS) {
        this.i++
        res.dataList.forEach((element, i) => {
          element.name = _this.assembly_ID == 0 ? element.Districtname : element.Assemblyname;
          element.code = _this.assembly_ID == 0 ? element.DistrictID : element.AssemblyID;
        });
        if (this.assembly_ID == 0) {
          this.districtDashboardData = res;
        } else {
          this.assemblyListDashboardData = res;
        }
        this.data = res;

        this.setSelectedIdByDashboard();
      } else {
        this.loaderOFF.loaderShow = false;
        console.log('Dashborad API Response Code => ' + res.API_CODE + ' & Msg => ' + res.API_MSG);
        this.cardList = [{
          count: 0,
          key: "Total Camera",
          img: "../assets/dashboard/TotalCamera.png",
          colorCode: this.colorCodes[0]
        },
        {
          count: 0,
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: 0,
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: 0,
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: 0,
          key: "Last 60 Mins Online",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }];
      }
    },
      err => {
        this.loaderOFF.loaderShow = false;
        this.cardList = [{
          count: 0,
          key: "Total Camera",
          img: "../assets/dashboard/TotalCamera.png",
          colorCode: this.colorCodes[0]
        },
        {
          count: 0,
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: 0,
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: 0,
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: 0,
          key: "Last 60 Mins Online",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }];
      });
  }

  getEnumList() {
    let PollingStationCategory = 4;
    this.dashboardService.getEnumList(PollingStationCategory, true).subscribe(res => {
      this.cameraStatusList = res;
      this.cameraStatusList.unshift({ key: 0, value: "ALL" })
    })
  }

  getDropdownList() {

    let zoneId = this.dynamicData.zoneId
    this.dashboardService.getZoneIdByDistrictList(zoneId, true).subscribe(res => {
      this.districtList = res;
      this.districtList.unshift({ key: 0, regionalValue: "ALL" });
      this.assemblyList.unshift({ key: 0, regionalValue: "ALL" });
      let _this = this;
      if (_this.roleTypeId == _this.RoleType.ACAdmin || _this.roleTypeId == _this.RoleType.AssemblyControlCenter || _this.dynamicData.parliamentConstitutionId > 0 && _this.dynamicData.assemblyConstitutionId > 0) {
        this.isAssemblyDisabled = true;
        this.isDistrictDisabled = true;
        this.dashboardService.getDistrictIdByAssemblyList(this.district_ID, true).subscribe(res => {
          this.assemblyList = res;
          this.assemblyList.unshift({ key: 0, regionalValue: "ALL" });
          this.getDashboradList();
        })
      } else {
        if (_this.roleTypeId == _this.RoleType.DistrictAdmin || _this.roleTypeId == _this.RoleType.DistrictControlCenter || _this.dynamicData.parliamentConstitutionId > 0) {
          this.isDistrictDisabled = true;
          this.dashboardService.getDistrictIdByAssemblyList(this.district_ID, true).subscribe(res => {
            this.assemblyList = res;
            this.assemblyList.unshift({ key: 0, regionalValue: "ALL" });
            this.getDashboradList();
          })
        } else {
          this.getDashboradList();
        }

      }

    },
      err => {
        this.loaderOFF.loaderShow = false;
        this.cardList = [{
          count: 0,
          key: "Total Camera",
          img: "../assets/dashboard/TotalCamera.png",
          colorCode: this.colorCodes[0]
        },
        {
          count: 0,
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: 0,
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: 0,
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: 0,
          key: "Last 60 Mins Online",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }];
      })
  }
  btnStatus() {
    let setData = {
      district_ID: this.district_ID,
      assembly_ID: this.assembly_ID,
      currentView: Navigationlevel.level_01,
      breadcrumb: '',
      pageTitle: this.dynamicData?.label_01
    }
    this.cardDetailsData = {};
    localStorage.setItem('currentData', JSON.stringify(setData));
    // localStorage.setItem('filterType','ALL');
    this.isViewCardDetails = !this.isViewCardDetails;
    this.counter = 30;
    //this.isListView = true;
    // this.stopApiCalls();
  }
  btnBack() {
    this.isHoursData = 'N';
    let data = JSON.parse(localStorage.getItem('currentData'))
    if (data.currentView == Navigationlevel.level_04) {
      data.currentView = Navigationlevel.level_03;
      let a = data.breadcrumb.split("|");
      let b = a[2].split("\n")
      data.breadcrumb = ' | ' + a[1] + ' | ' + b[0];
      data.pageTitle = this.dynamicData?.label_03
    }
    else if (data.currentView == Navigationlevel.level_03) {
      data.currentView = Navigationlevel.level_02;
      let a = data.breadcrumb.split("|");
      data.breadcrumb = ' | ' + a[0] + a[1];
      data.pageTitle = this.dynamicData?.label_02
    }
    else if (data.currentView == Navigationlevel.level_02) {
      let a = data.breadcrumb.split("|");
      data.breadcrumb = '';
      data.currentView = Navigationlevel.level_01;
      data.pageTitle = this.dynamicData?.label_01;
      data.district_ID = this.dynamicData?.parliamentConstitutionId > 0 ? this.dynamicData?.parliamentConstitutionId : 0;
      this.isBackBtn = false;
      this.isListView = false;
      let backfromlivestreaming = {
        district_ID: 0,
        isBack: 'N',
        currentData: data
      }
      localStorage.setItem('backfromlivestreaming', JSON.stringify(backfromlivestreaming))
      this.getDashboradList();
    } else if (data.currentView == Navigationlevel.level_01) {
      data.currentView = Navigationlevel.level_0;
      this.isViewCardDetails = !this.isViewCardDetails;
      this.district_ID = this.dynamicData.parliamentConstitutionId;
      this.assembly_ID = this.dynamicData.assemblyConstitutionId;
      // this.isListView = false;
      this.isBackBtn = true;
      this.getDashboradList();
      this.counter = 30;
      // this.timeoutId = setTimeout(() => {
      this.startApiCalls();
      // }, this.intervalTime);
    }
    this.currentViewData = data;
    localStorage.setItem('currentData', JSON.stringify(data))
    this.cardDetailsData = {};
  }
  btnHome() {
    this.isHoursData = 'N';
    let setData = {
      district_ID: this.dynamicData.parliamentConstitutionId,
      assembly_ID: this.dynamicData.assemblyConstitutionId,
      currentView: Navigationlevel.level_01,
      breadcrumb: '',
      pageTitle: this.dynamicData?.label_01
    }
    this.cardDetailsData = {};
    this.currentViewData = setData;
    let backfromlivestreaming = {
      district_ID: 0,
      isBack: 'N',
      currentData: setData
    }
    localStorage.setItem('backfromlivestreaming', JSON.stringify(backfromlivestreaming))
    localStorage.setItem('currentData', JSON.stringify(setData))
    this.isViewCardDetails = !this.isViewCardDetails;
    this.district_ID = this.dynamicData.parliamentConstitutionId;
    this.assembly_ID = this.dynamicData.assemblyConstitutionId;
    this.counter = 30;
    this.isListView = false;
    this.isBackBtn = false;
    let event = { checked: false }
    this.openListView(event)
    this.getDashboradList();
    this.startApiCalls();
  }
  reset() {
    let setData = {
      district_ID: this.dynamicData.parliamentConstitutionId,
      assembly_ID: this.dynamicData.assemblyConstitutionId,
      currentView: Navigationlevel.level_01,
      breadcrumb: '',
      pageTitle: this.dynamicData?.label_01
    }
    this.cardDetailsData = {};
    this.currentViewData = setData;
    localStorage.setItem('currentData', JSON.stringify(setData))
    this.district_ID = this.dynamicData.parliamentConstitutionId;
    this.assembly_ID = this.dynamicData.assemblyConstitutionId;
    this.cameraLocationId = 0;
    if (environment.apiBaseUrl.includes("klccvapi")) {
      this.cameraStatusId = 2;
    } else {
      this.cameraStatusId = 0;
    }
    this.getDashboradList();
    this.counter = 30;

    //   this.timeoutId = setTimeout(() => {
    this.startApiCalls();
    this.onCameraLocationChange();
    // }, this.intervalTime);
  }
  refresh() {
    this.getDashboradList();
    localStorage.setItem('cameraLocationId', this.cameraLocationId);
    localStorage.setItem('cameraStatusId', this.cameraStatusId)
    this.sharedService.emitChange(this.cameraLocationId);
  }
}
