import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    private emitChangeSource = new Subject<any>();
    private emitChangeSourceMenu = new Subject<any>();
    private emitChangeSourceMenu1 = new Subject<any>();
    private emitChangeSourceDarkMode = new Subject<any>();
    changeEmitted$ = this.emitChangeSource.asObservable();
    emitChangeSourceMenu$ = this.emitChangeSourceMenu.asObservable();
    emitChangeSourceMenu1$ = this.emitChangeSourceMenu1.asObservable();
    emitChangeSourceDarkMode$ = this.emitChangeSourceDarkMode.asObservable();
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }
    loadMenu(data: any) {
        this.emitChangeSourceMenu.next(data);
    }
    loadMenu1(data: any) {
        this.emitChangeSourceMenu1.next(data);
    }
    darkModeevent(data: any) {
        this.emitChangeSourceDarkMode.next(data);
    }
}
