// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

    apiBaseUrl: 'https://gccrcapi.inetstream.live',
    imageBaseurl: 'https://gccrcimages.inetstream.live',
    title: 'GccRc',
    tokenEndPoint: '/api/token',
    StoppageIntervalSecs: 300,
    dataInterval: 10000,
    production: true,
    environment: 'PROD',
    showEnvironment: false,
    PoliceTransportCategoryId: 12,
    Livetrackingcol: 'col-md-6',
    PoliceRoleIds: [22],
    stateName: 'TN',
    loginImageUrl: 'assets/tn.png',
    sideBarImageUrl: 'assets/img/ico/inet-logo.png',
    evtTitle: 'I-NET Secure Labs Pvt. Ltd. ',
    loginTitle: '',
    theme: 'ThemeTwo',
    signallost: false,
    thirdPartyBaseURL: "https://electionwebcast.cloudvms.in/webELE.asmx/ELE_API_",
    thirdPartyAPI_KEY: 'dYajOhh9x5kZa1zKndMv9Hms6ohOy6TA',
    checkthirdPartyURL: '/api/',
    buildVersion: 'v-2',
    width: '50px',
    dashboardCardText: 'Installed'
};
