import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import { ReportsService } from 'src/app/services/reports.service';
@Component({
  selector: 'app-showreport',
  templateUrl: './showreport.component.html',
  styleUrls: ['./showreport.component.scss']
})
export class ShowReportComponent implements OnInit {
  routeParams: any;
  reportUrl: any;
  submitbtn: string;
  title: string;

  @Input()
  url: SafeResourceUrl;
  src: string;
  pdfSource: any;
  src1: any;

  reportName: any;
  datetimeFormat = moment(new Date()).format('YYYY-MM-DD-HH-mm');

  constructor(
    private reportService: ReportsService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowReportComponent>,
    public sanitizer: DomSanitizer
  ) {
    if (data != null && data.reportType) {
      this.reportName = 'Garuda Report';
      switch (data.reportType) {
        case 5:
          this.reportName = 'Distance Travelled Report';
          break;

        case 6:
          this.reportName = 'Notification Report';
          break;

        case 7:
          this.reportName = 'Seized Report';
          break;

        case 8:
          this.reportName = 'SLA Report';
          break;

        case 9:
          this.reportName = 'SLA Summary Report';
          break;

        case 10:
          this.reportName = 'Device Status Report';
          break;

        case 11:
          this.reportName = 'Audit Report';
          break;

        case 12:
          this.reportName = 'Vehicle Matrix Report';
          break;
        case 13:
          this.reportName = 'Log Report';
          break;
        case 14:
          this.reportName = 'Device Audit Report';
          break;
        case 10:
          this.reportName = 'Device Count Report';
          break;
        case 11:
          this.reportName = 'SLA Matrix Report';
          break;
        case 19:
          this.reportName = 'SLA Percentage Report';
          break;
        case 20:
          this.reportName = 'SLA Percentage Summary Report';
          break;
        case 21:
          this.reportName = 'District Wise Vehicle Count';
          break;
        case 22:
          this.reportName = 'SLA Vehicle Count Report';
          break;
        case 23:
          this.reportName = 'Day Wise Distance Traveled Report';
          break;
        case 24:
          this.reportName = 'Speed Violation Report';
          break;
        default:
          this.reportName = 'Falcon Report';
          break;
      }
    }
  }

  ngOnInit() {
    console.log(JSON.stringify(this.data));
    this.reportService.tripreport(this.data).subscribe(result => {
      if (result) {
        const decodedStringAtoB = atob(result);
        this.src1 = this.sanitizer.bypassSecurityTrustHtml(decodedStringAtoB);
      }
    });
  }

  downloadpdf() {
    const pdfdata = this.data;
    pdfdata.downloadType = 1;
    this.reportService.tripreport(pdfdata).subscribe(result => {
      if (result) {
        this.pdfSource = result;
        const linkSource = 'data:application/pdf;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.reportName + '-' + this.datetimeFormat + '.pdf';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  downloadexcel() {
    const printdata = this.data;
    printdata.downloadType = 2;
    this.reportService.tripreport(printdata).subscribe(result => {
      if (result) {
        const linkSource = 'data:application/vnd.ms-excel;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.reportName + '-' + this.datetimeFormat + '.xls';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  downloadhtml() {
    const printdata = this.data;
    printdata.downloadType = 4;
    this.reportService.tripreport(printdata).subscribe(result => {
      if (result) {
        const linkSource = 'data:text/html;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.reportName + '-' + this.datetimeFormat + '.html';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  print() {
    const exceldata = this.data;
    exceldata.downloadType = 1;
    this.reportService.tripreport(exceldata).subscribe(result => {
      if (result) {
        this.printPreview(result);
      }
    });
  }

  downloadword() {
    const worddata = this.data;
    worddata.downloadType = 3;
    this.reportService.tripreport(worddata).subscribe(result => {
      if (result) {
        const linkSource = 'data:application/msword;base64, ' + result;
        const downloadLink = document.createElement('a');
        const fileName = this.reportName + '-' + this.datetimeFormat + '.doc';
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }
    });
  }

  printPreview = (data, type = 'application/pdf') => {
    let blob = null;
    blob = this.b64toBlob(data, type);
    const blobURL = URL.createObjectURL(blob);
    const theWindow = window.open(blobURL);
    const theDoc = theWindow.document;
    const theScript = document.createElement('script');
    function injectThis() {
      window.print();
    }
    theScript.innerHTML = `window.onload = ${injectThis.toString()};`;
    theDoc.body.appendChild(theScript);
  };

  b64toBlob = (content, contentType) => {
    contentType = contentType || '';
    const sliceSize = 512;
    const byteCharacters = window.atob(content);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, {
      type: contentType
    });
    return blob;
  };

  onCancel() {
    this.dialogRef.close();
  }

  close(isProceed) {
    this.dialogRef.close(isProceed);
  }

}
