export enum API_ENDPOINTS {
    UserDashboard = 'UserDashboard',
    DistrictList = 'DistrictList',
    AssemblyList = 'AssemblyList',
    AssemblyUserDashboard = 'AssemblyUserDashboard',
    BoothList = 'BoothList',
    AHM_ALLBoothList = 'AHM_ALLBoothList',
    CamHourList = 'CamHourList',
    ZoneIdByDistrictList = '/api/lookup/district/',
    DistrictIdByAssemblyList = '/api/lookup/assembly/',
    ProjectConfig = '/api/config/project',
    ConfigUpdate = '/api/config/update',
    importDistrict = '/api/importDistrict',
    importAssembly = '/api/importAssembly',
    importPollingStation = '/api/importPollingStation',
    assemblyUserList = '/api/user/Assembly',
    assemblyCoordinator = '/api/user/AssemblyCoordinator',
    districtUserList = '/api/user/District',
    importExcelValidateAssemblyUsers = '/api/user/Assembly/ImportExcelValidate',
    uploadAssemblyUsers = '/api/user/Assembly/Import',
    importExcelValidateDistrictUsers = '/api/user/District/ImportExcelValidate',
    uploadDistrictUsers = '/api/user/District/Import',
    assemblyExcelData = '/api/user/AssemblyExcelData',
    districtExcelData = '/api/user/DistrictExcelData',
    controlCenter = '/api/user/ControlCenter/Save',
    aLLBoothList = "ALLBoothList",
    complaintUpdate = '/api/Complaint/Update',
    pollingStationApprove = '/api/PollingStation/Approve',
    pollingStationExcelData = '/api/user/PollingStationExcelData',
    networkStatusList = '/api/networkStatus/List',
    importDeoDistrict = '/api/importDeoDistrict',
    importDeoAssembly = '/api/importDeoAssembly',
    attendance = '/api/attendance',
    GetEnumList = '/api/lookup/GetEnumList'
}
