import { NgModule } from '@angular/core';
import { OnlyNumber } from "./onlynumber.directive";
import { SpecialCharacter } from "./onlychar.directive";
import { onlycharSpecialCharacter } from './onlycharSpecial.directive';
import { AlphanumericOnlyDirective } from './appAlphanumericOnly.directive';

export const components = [
  OnlyNumber,SpecialCharacter, onlycharSpecialCharacter,AlphanumericOnlyDirective
];

@NgModule({
  declarations: [components],
  exports: [components]
})
export class DirectivesModule {}
