import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './views/layout/layout.module';
import { AuthGuard } from './core/guard/auth.guard';
import { AppComponent } from './app.component';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlertService } from './services/alert.service';
import { AuthenticationService } from './services/authentication.service';
import { DataService } from './services/data.service';
import { HttpInterceptorService } from './services/interceptor.service';
import { UserSessionService } from './services/usersession.service';
 import { NgHttpLoaderModule } from 'ng-http-loader';
import { RoleService } from './services/role.service';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';

import { UtilityService } from './services/utility.service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavigationService } from './services/navigation.service';
import { ExcelService } from './services/excel.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LivetrackService } from './services/livetrack.service';
import { ShowReportModule } from './views/pages/reports/showreport/showreport.module';
import { LightboxModule } from 'ngx-lightbox';

import { UserIdleModule } from 'angular-user-idle';
import { OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';

import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';
import { DashboardService } from './services/dashboard.service';
import { VehicleService } from './services/vehicle.service';
import { FileviewerModule } from './views/popups/fileviewer/fileviewer.module';
import { FileviewerComponent } from './views/popups/fileviewer/fileviewer.component';

//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { PopupdialogComponent } from './views/pages/popupdialog/popupdialog.component';
import { ViewComponent } from './views/pages/components/view/view.component';
import { SharingModule } from './views/pages/components/sharing.module';
import { GlobalProvider } from './services/global';
import { PopupComponent } from './views/pages/popup/popup.component';
import { SafePipeModule } from './pipe/safe.pipe';
import { ReportpopupComponent } from './views/pages/reportpopup/reportpopup.component';
import { GoogleChartsModule } from 'angular-google-charts';



export function createTranslateLoader(http: HttpClient) {
  // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    PopupdialogComponent,
    PopupComponent,
    ReportpopupComponent,
    // LogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ShowReportModule,
    LayoutModule,
    NgbDropdownModule,
    HttpClientModule,
    SafePipeModule,
    // EditModule,
    // LogModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
     NgHttpLoaderModule.forRoot(),
     GoogleChartsModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LightboxModule,
    // UserIdleModule.forRoot({ idle: 300, timeout: 10, ping: 30 }),
    MatSelectModule,
    MatSelectFilterModule,
    // LogModule,
    FileviewerModule,
    SharingModule
   // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthGuard,
    NavigationService,
    LivetrackService,
    GlobalProvider,
    {
      provide: HIGHLIGHT_OPTIONS, // https://www.npmjs.com/package/ngx-highlightjs
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
        }
      }
    },
    DataService,
    AuthenticationService,
    AlertService,
    RoleService,
    ExcelService,
    UtilityService,
    DashboardService,
    VehicleService,
    UserSessionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    // { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false }}
    //{provide: OWL_DATE_TIME_LOCALE, useValue: 'en-IN'},
    { provide: OWL_MOMENT_DATE_TIME_ADAPTER_OPTIONS, useValue: { useUtc: false } },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FileviewerComponent,
    PopupComponent
  ],

})
export class AppModule { }
