import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/sharedService';
import { ThirdPartyService } from 'src/app/services/thirdparty.service';
import { API_ENDPOINTS } from 'src/enum/api-endpoint';
import { RESPONSE_CODE } from 'src/enum/responseCode';
import _ from "lodash";
import { ExcelService } from 'src/app/services/excel.service';
import { AlertService } from 'src/app/services/alert.service';
import { Navigationlevel } from 'src/enum/navigationlevel.enum';
import { UserSessionService } from 'src/app/services/usersession.service';
import { RoleType } from 'src/enum/roletype';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../../popup/popup.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss']
})
export class CardDetailsComponent implements OnInit {
  @Input() cardDetailsData: any;
  // selectedData: any = { isSelectedDistrict: false, districtData: '', isSelectedAssembly: false, assemblyData: '', isSelectedPollingStation: false, pollingStationData: '' };
  @Output() message: EventEmitter<string> = new EventEmitter();
  localData: any = [];
  assemblyList: any = []
  pollingStationList: any = []
  district_ID: number = 0;
  assembly_ID: number = 0;
  cardList: any = [];
  data: any = [];
  liveList: boolean = false;
  webDataID: number = 0;
  pageNumber: number = 1;
  districtList: any = [];
  pollingStationId: number = 0;
  frameOptionList = [];
  SortList = [];
  selectedFrameOption: string = '';
  selectedSize: any = 1;
  videoURL = "";
  dynamicCol: string = 'col-md-4';
  show: boolean = false;
  filteredDataList: any[] = [];
  searchText: string = '';
  cameraSts: boolean = true;
  cameraVal: any = 1;
  filterList: any = [];
  sortType: string = 'Districtname';
  filterType: string = 'ALL';
  allPollingStationCamera: boolean = false;
  onlineVideoURLList: any = [];
  currentRandomItem: any = [];
  items: any = [];
  isAutoRoate: boolean = false;
  label: any = [];
  Navigationlevel: any = Navigationlevel;
  dynamicData: any = {};
  roleType: any
  filterName: string;
  roleTypeId: number = 0;
  RoleType: any = RoleType;
  roleId: number;
  cameraLocationId: any = 0;
  cameraStatusId: any = 0;
  isAllowedExcel: boolean = false;
  isDarkmode: boolean = false;

  constructor(private thirdPartyService: ThirdPartyService, private sharedService: SharedService, private excelService: ExcelService, private alertService: AlertService,
    private userSessionService: UserSessionService, public dialog: MatDialog, private router: Router) {
    this.frameOptionList = [
      { key: 1, value: "3x2" },
      { key: 2, value: "3x3" },
      { key: 3, value: "3x4" },
      { key: 4, value: "4x4" },
    ];
    this.SortList = [
      { key: 'Districtname', value: "District Name" },
      { key: 'OfflineCam', value: "Offline Camera" },
      { key: 'OnlineCam', value: "Online Camera" },
      { key: 'OnceliveCam', value: "Oncelive Camera" },
    ];
    this.filterList = [
      { key: 'ALL', value: "ALL" },
      { key: 'OfflineCam', value: "Offline Camera" },
      { key: 'OnlineCam', value: "Online Camera" },
      { key: 'OnceliveCam', value: "Oncelive Camera" },
    ];
    this.roleId = this.userSessionService.roleId();

    try {
      this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));
      this.district_ID = this.dynamicData.parliamentConstitutionId;
      this.assembly_ID = this.dynamicData.assemblyConstitutionId;
      let getRoleName = this.userSessionService.roleName();
      this.roleTypeId = this.userSessionService.roleId();
      this.roleType = getRoleName.toUpperCase();
      if ((environment.apiBaseUrl.includes("ddapi") || environment.apiBaseUrl.includes("ahapi")) && this.roleTypeId == RoleType.DistrictControlCenter) {
        this.isAllowedExcel = true;
      }
    } catch (error) {

    }

    this.sharedService.changeEmitted$.subscribe((res: any) => {
      this.data = JSON.parse(localStorage.getItem('currentData'))
      this.cameraLocationId = localStorage.getItem('cameraLocationId');
      this.cameraStatusId = localStorage.getItem('cameraStatusId');
      this.getList(this.data);

    });
    let darkThemeclr: any = localStorage.getItem('isdarktheme')
    darkThemeclr = darkThemeclr == 'false' ? false : true;
    this.isDarkmode = darkThemeclr;
  }

  ngOnInit(): void {
  }



  onframeOptionChange(event) {
    this.selectedSize = event[0].value;
    switch (this.selectedSize) {
      case 1:
        return this.dynamicCol = 'col-md-4';
      case 2:
        return this.dynamicCol = 'col-sm-4';
      case 3:
        return this.dynamicCol = 'col-sm-3';
      case 4:
        return this.dynamicCol = 'col-sm-3';
      default:
        return this.dynamicCol = 'col-sm-4'; // Default to col-sm-6 if none of the cases match
    }
  }

  btnSelectData(e) {
    // let data = JSON.parse(localStorage.getItem('projectConfig'));
    // data = data.filter(k => k.id == 15);
    // this.label = data[0].value;
    // let labelSlice = this.label;
    // labelSlice = labelSlice.slice(0, 4)
    this.searchText = '';
    if (this.data.currentView == Navigationlevel.level_01) {
      this.data.currentView = Navigationlevel.level_02;
      this.district_ID = e.code;
      this.data.breadcrumb = ' | ' + e.name;
      this.data.pageTitle = this.dynamicData?.label_02;
      this.data.isBackBtn = true;
      this.data.parliamentConstitutionId = e.code;
      localStorage.setItem('currentData', JSON.stringify(this.data))
      this.getList(this.data)
    } else if (this.data.currentView == Navigationlevel.level_02) {
      this.data.currentView = Navigationlevel.level_03;
      this.assembly_ID = e.code;
      this.data.breadcrumb = this.data.breadcrumb + ' | ' + e.name;
      this.data.pageTitle = this.dynamicData?.label_03
      this.data.isBackBtn = true;

      this.getList(this.data);
      let getcurrentData = JSON.parse(localStorage.getItem('currentData'))
      let obj = {

        "district_ID": getcurrentData.district_ID,
        "assembly_ID": getcurrentData.assembly_ID,
        "currentView": getcurrentData.currentView,
        "breadcrumb": getcurrentData.breadcrumb,
        "pageTitle": getcurrentData.pageTitle,
        "isBackBtn": getcurrentData.isBackBtn,
        "assemblyCount": getcurrentData.assemblyCount

      }
      let sentDataLiveStreaming = {
        district_ID: this.district_ID,
        assembly_ID: this.assembly_ID,
        drilldown: 'Y',
        currentData: obj
      }
      localStorage.setItem('currentData', JSON.stringify(this.data))
      localStorage.setItem('sentDataLiveStreaming', JSON.stringify(sentDataLiveStreaming));
      this.router.navigate(['/livestreamingview']);
    } else if (this.data.currentView == Navigationlevel.level_03) {
      this.data.currentView = Navigationlevel.level_04;
      this.pollingStationId = e.code;
      this.data.breadcrumb = this.data.breadcrumb + ' \n  ' + e.name;
      this.data.pageTitle = '';
      let localData = this.cardList;
      localData.forEach(element => {
        let DT_PRE = element.DT_PRE != '' ? element.DT_PRE + '/' : '';
        let locationName = DT_PRE + element.District_ORDER + '/' + element.Assembly_ORDER + '/' + element.PSNum + '_' + element.Location;
        element.fullLocationName = locationName.toLocaleUpperCase();
      });
      this.onlineVideoURLList = localData.filter(j => j.code == e.code);
      this.dynamicCol = 'col-md-6';
      this.data.isBackBtn = true;
      localStorage.setItem('currentData', JSON.stringify(this.data))
      this.message.emit('');

      // this.getList(this.data)
    }
  }
  ngOnChanges() {
    try {
      debugger;

      this.filterType = 'ALL';
      this.searchText = '';
      this.sortType = 'Districtname';
      this.cameraLocationId = localStorage.getItem('cameraLocationId');
      this.cameraStatusId = localStorage.getItem('cameraStatusId');
      this.data = JSON.parse(localStorage.getItem('currentData'))
      if (this.data.currentView == Navigationlevel.level_04) {
        this.cardList = this.pollingStationList;
        this.cameraFilterChange(localStorage.getItem('filterType'));
      }
      else if (this.data.currentView == Navigationlevel.level_03) {
        this.cardList = this.pollingStationList;
        this.cameraFilterChange(localStorage.getItem('filterType'));
      } else if (this.data.currentView == Navigationlevel.level_02) {
        this.isAutoRoate = false;
        this.selectedSize = 1;
        this.allPollingStationCamera = false;
        this.onlineVideoURLList = [];
        if (this.roleTypeId == RoleType.ACAdmin) {
          let localData = this.assemblyList
          this.cardList = localData.filter(l => l.code == this.dynamicData.assemblyConstitutionId);
          if (this.cardList.length == 0) {
            let backfromlivestreaming = JSON.parse(localStorage.getItem('backfromlivestreaming'));
            this.district_ID = backfromlivestreaming.district_ID;
            this.getList(this.data);
          }

        } else {
          let backfromlivestreaming = JSON.parse(localStorage.getItem('backfromlivestreaming'));
          if (backfromlivestreaming.isBack == 'Y') {
            this.district_ID = backfromlivestreaming.district_ID;
            let setData = {
              district_ID: this.district_ID,
              assembly_ID: this.assembly_ID,
              currentView: Navigationlevel.level_01,
              breadcrumb: '',
              pageTitle: this.dynamicData?.label_01
            }
            let setbackfromlivestreaming = {
              district_ID: 0,
              isBack: 'N',
              currentData: setData
            }
            localStorage.setItem('backfromlivestreaming', JSON.stringify(setbackfromlivestreaming))
          }

          this.cardList = this.assemblyList;
          this.getList(this.data);
        }
        // this.cardList = this.assemblyList;
        this.cameraFilterChange(localStorage.getItem('filterType'));
      } else {
        this.allPollingStationCamera = false;
        this.getList(this.data);
      }
    } catch (error) {

    }


  }
  cameraSortOrderChange(event) {
    let localData = this.cardList;
    if (environment.title == 'Puducherry') {
      let sortedDistrictData = localData
      if (this.data.currentView == Navigationlevel.level_01) {
        sortedDistrictData = [...localData].sort((a, b) => a.District_ORDER - b.District_ORDER);

      } else if (this.data.currentView == Navigationlevel.level_02) {
        sortedDistrictData = [...localData].sort((a, b) => a.Assembly_ORDER - b.Assembly_ORDER);
      } else if (this.data.currentView == Navigationlevel.level_03) {
        sortedDistrictData = [...localData].sort((a, b) => a.Booth_ID - b.Booth_ID);
      }
      this.cardList = sortedDistrictData;
    } else {
      let sortedDistrictData = localData;
      let sortedType = 'District_ORDER';
      if (this.data.currentView == Navigationlevel.level_01) {
        sortedType = 'District_ORDER'
      } else if (this.data.currentView == Navigationlevel.level_02) {
        sortedType = 'Assembly_ORDER'
      } else if (this.data.currentView == Navigationlevel.level_03) {
        sortedType = 'Booth_ID'
      }
      let zeroCamEntries = localData.filter(item => item.TotalCam === 0);
      zeroCamEntries = _.sortBy(zeroCamEntries, [sortedType], ['asc'])
      let nonZeroCamEntries = localData.filter(item => item.TotalCam !== 0);
      nonZeroCamEntries = _.sortBy(nonZeroCamEntries, [sortedType], ['asc'])
      const finalList = [...nonZeroCamEntries, ...zeroCamEntries];
      this.cardList = finalList;
    }

  }

  cameraFilterChange(e) {
    let localData = [];
    if (e == null || e == '' || e == undefined) {
      e = "ALL";
      this.filterType = "ALL"
    }
    if (e == 'OfflineCam') {
      this.filterName = "Offline"
    }
    else if (e == 'OnlineCam') {
      this.filterName = "Online"
    }
    else if (e == "OnceliveCam") {
      this.filterName = "Once Live"
    }
    if (this.data.currentView == Navigationlevel.level_01) {
      localData = this.districtList;
    } else if (this.data.currentView == Navigationlevel.level_02) {
      localData = this.assemblyList;
    } else if (this.data.currentView == Navigationlevel.level_03) {
      localData = this.pollingStationList;
    }
    if (e == 'ALL') {
      this.cardList = localData;
    } else {
      localData = localData.filter(j => j[e] > 0);
    }
    // this.cardList = localData;
    this.sortType = e == 'ALL' ? 'Districtname' : e;
    if (environment.title == 'Puducherry') {
      let sortedDistrictData = localData
      if (this.data.currentView == Navigationlevel.level_01) {
        sortedDistrictData = [...localData].sort((a, b) => a.District_ORDER - b.District_ORDER);
      } else if (this.data.currentView == Navigationlevel.level_02) {
        sortedDistrictData = [...localData].sort((a, b) => a.Assembly_ORDER - b.Assembly_ORDER);
      } else if (this.data.currentView == Navigationlevel.level_03) {
        sortedDistrictData = [...localData].sort((a, b) => a.Booth_ID - b.Booth_ID);
      }
      this.cardList = sortedDistrictData;
    } else {
      let sortedDistrictData = localData;
      let sortedType = 'District_ORDER';
      if (this.data.currentView == Navigationlevel.level_01) {
        sortedType = 'District_ORDER'
      } else if (this.data.currentView == Navigationlevel.level_02) {
        sortedType = 'Assembly_ORDER'
      } else if (this.data.currentView == Navigationlevel.level_03) {
        sortedType = 'Booth_ID'
      }
      let zeroCamEntries = localData.filter(item => item.TotalCam === 0);
      zeroCamEntries = _.sortBy(zeroCamEntries, [sortedType], ['asc'])
      let nonZeroCamEntries = localData.filter(item => item.TotalCam !== 0);
      nonZeroCamEntries = _.sortBy(nonZeroCamEntries, [sortedType], ['asc'])
      const finalList = [...nonZeroCamEntries, ...zeroCamEntries];
      this.cardList = finalList;
    }
    // this.cameraSortOrderChange(this.sortType);
  }
  onCameraStreaming(e) {
    let localData = this.cardList;
    localData.forEach(element => {
      let DT_PRE = element.DT_PRE != '' ? element.DT_PRE + '/' : '';
      let locationName = DT_PRE + element.District_ORDER + '/' + element.Assembly_ORDER + '/' + element.PSNum + '_' + element.Location;
      element.fullLocationName = locationName.toLocaleUpperCase();
    });
    this.onlineVideoURLList = localData.filter(j => j.CameraOnline == true);
  }
  getList(data) {
    let payload = {};
    let _this = this;
    let URL = '';

    if (data.currentView == Navigationlevel.level_01) {
      payload = {
        "WebUSER": this.dynamicData?.webUser,
        "WebproID": this.dynamicData?.webProId,
        "psFlag": parseInt(this.cameraLocationId),
        "cvlFlag": parseInt(this.cameraStatusId),
        //"LUFlag":this.dynamicData?.LUFlag
      }
      URL = API_ENDPOINTS.UserDashboard;
    } else if (data.currentView == Navigationlevel.level_02) {
      payload = {
        "WebUSER": this.dynamicData?.webUser,
        "WebproID": this.dynamicData?.webProId,
        "DistrictID": this.district_ID,
        "psFlag": parseInt(this.cameraLocationId),
        "cvlFlag": parseInt(this.cameraStatusId),
        // "LUFlag":this.dynamicData?.LUFlag
      }
      URL = API_ENDPOINTS.AssemblyUserDashboard;
    } else if (data.currentView == Navigationlevel.level_03) {
      payload = {
        "WebUSER": this.dynamicData?.webUser,
        "WebproID": this.dynamicData?.webProId,
        "District_ID": this.district_ID,
        "Assembly_ID": this.assembly_ID,
        "LiveList": this.liveList,
        "WebDataID": this.webDataID,
        "PageNumber": this.pageNumber,
        "psFlag": parseInt(this.cameraLocationId),
        "cvlFlag": parseInt(this.cameraStatusId),
        //"LUFlag":this.dynamicData?.LUFlag
      }
      URL = API_ENDPOINTS.BoothList;
    }

    this.thirdPartyService.postMethod(URL, payload).subscribe(res => {
      if (res.API_CODE == RESPONSE_CODE.SUCCESS) {
        try {
          _this.filterType = localStorage.getItem('filterType')
          _this.filterType = _this.filterType == null ? 'ALL' : _this.filterType;
          if (_this.filterType == 'OfflineCam') {
            _this.filterName = "Offline"
          }
          else if (_this.filterType == 'OnlineCam') {
            _this.filterName = "Online"
          }
          else if (_this.filterType == "OnceliveCam") {
            _this.filterName = "Once Live"
          }
          if (data.currentView == Navigationlevel.level_01) {
            let districtList = res.dataList;
            districtList.forEach((element, i) => {
              element.name = element.District_ORDER > 0 ? element.District_ORDER + '-' + element.Districtname : element.Districtname;
              element.code = element.DistrictID;

            });
            if (this.data.district_ID > 0) {
              let localData = districtList;
              let filterData = localData.filter(j => j.DistrictID == this.data.district_ID);
              this.districtList = filterData;
              this.cardList = this.districtList;
            } else {
              this.districtList = districtList;
              this.cardList = this.districtList;

              this.cameraSortOrderChange('District_ORDER');
              // this.cameraFilterChange(localStorage.getItem('filterType'));
            }


          } else if (data.currentView == Navigationlevel.level_02) {
            res.dataList.forEach((element, i) => {
              element.name = element.Assembly_ORDER > 0 ? element.Assembly_ORDER + '-' + element.Assemblyname : element.Assemblyname;
              element.code = element.AssemblyID;

            });
            if (_this.roleTypeId == RoleType.ACAdmin) {
              let localData = res.dataList;
              localData = localData.filter(l => l.code == _this.dynamicData.assemblyConstitutionId);
              if (localData.length > 0) {
                _this.cardList = localData;
                _this.assemblyList = localData;
              } else {
                _this.assemblyList = res.dataList;
                _this.cardList = _this.assemblyList;
              }
              // localData = localData.filter(l => l.code == _this.dynamicData.assemblyConstitutionId);

            } else {

              if (_this.data.assembly_ID > 0) {
                let localData = res.dataList;
                localData = localData.filter(l => l.code == _this.data.assembly_ID);
                _this.assemblyList = localData;
                _this.cardList = _this.assemblyList;
              } else {
                if (this.dynamicData?.assemblyConstitutionId > 0) {
                  this.assembly_ID = this.dynamicData?.assemblyConstitutionId;
                  let localData = res.dataList;
                  localData = localData.filter(l => l.code == _this.assembly_ID);
                  _this.assemblyList = localData;
                  _this.cardList = _this.assemblyList;
                } else {
                  _this.assemblyList = res.dataList;
                  _this.cardList = _this.assemblyList;
                }

              }

            }
            this.data = JSON.parse(localStorage.getItem('currentData'));
            this.data.assemblyCount = _this.assemblyList.length;
            localStorage.setItem('currentData', JSON.stringify(this.data))
            this.message.emit('');
            this.cameraSortOrderChange('OfflineCam');
            this.filterType = localStorage.getItem('filterType')
            this.cameraFilterChange(localStorage.getItem('filterType'));
            this.cameraSortOrderChange('Assembly_ORDER');
          } else if (data.currentView == Navigationlevel.level_03) {
            res.BoothList.forEach((element, i) => {
              element.name = element.PSNum > 0 ? element.PSNum + '-' + element.Location : element.PSNum;
              element.code = element.PSNum;
              element.TotalCam = 1
              element.OnlineCam = element.CameraOnline ? 1 : 0;
              element.OfflineCam = element.OnlineCam == 1 ? 0 : 1;
              element.OnceliveCam = element.OnceLive ? 1 : 0
              element.OnlineCamPer = Math.floor((element.OnlineCam / element.TotalCam) * 100);
              element.OfflineCamPer = Math.floor((element.OfflineCam / element.TotalCam) * 100);
              element.OnceliveCamPer = Math.floor((element.OnceliveCam / element.TotalCam) * 100);
              element.IsOfflineCam = true;
              if (element.OnlineCamPer > element.OfflineCamPer) {
                element.IsOfflineCam = false;
              }
            });
            this.data = JSON.parse(localStorage.getItem('currentData'));
            localStorage.setItem('currentData', JSON.stringify(this.data))
            this.pollingStationList = res.BoothList;
            this.cardList = this.pollingStationList;
            this.message.emit('');
            this.cameraSortOrderChange('OfflineCam');
            this.filterType = localStorage.getItem('filterType')
            this.cameraFilterChange(localStorage.getItem('filterType'));

          }
        }
        catch (error) {

        }
      } else {
        this.cardList = [];
        this.message.emit('');
        console.log('Dashborad API Response Code => ' + res.API_CODE + ' & Msg => ' + res.API_MSG);
      }
    });
  }
  applyFilter(event: Event) {
    debugger;
    this.show = true;
    if (this.searchText == '') {
      this.show = false
    }
    // const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();  
    // this.filteredDataList = this.cardList.filter(item => item.name.toLowerCase().includes(filterValue));
    //   if (this.cardList.paginator) {
    //   this.cardList.paginator.firstPage();
    // }
  }


  cardremove() {
    debugger;
    this.show = false;
    this.searchText = '';


  }

  exportAsXLSX(): void {

    // if (environment.apiBaseUrl.includes('ahapi.electionpoll')) {
    //   let payload = {
    //     "WebUSER": this.dynamicData?.webUser,
    //     "WebproID": this.dynamicData?.webProId,
    //     "District_ID": this.district_ID,
    //     "Assembly_ID": this.assembly_ID,
    //     "LiveList": this.liveList,
    //     "WebDataID": this.webDataID,
    //     "PageNumber": this.pageNumber,
    //     "psFlag": parseInt(this.cameraLocationId),
    //     "cvlFlag": parseInt(this.cameraStatusId),
    //     //"LUFlag":this.dynamicData?.LUFlag
    //   }
    //   let URL = API_ENDPOINTS.AHM_ALLBoothList;


    //   this.thirdPartyService.postMethod(URL, payload).subscribe(res => {
    //     if (res.API_CODE == RESPONSE_CODE.SUCCESS) {
    //       try {
    //         if (!res.BoothList || res.BoothList.length === 0) {
    //           this.alertService.info('No data available to export');
    //           return;
    //         }
    //         let data = _.sortBy(res.BoothList, ['District_ORDER'], ['asc'])
    //         let excelColumns = [
    //           'District Name',
    //           'Assembly Name',
    //           'Polling Station',
    //           'Camera No',
    //           'Camera Location',
    //           'Camera Status',
    //         ];

    //         const excelList = [];
    //         data.forEach(a => {
    //           excelList.push({
    //             'District Name': a.District_ORDER + '-' + a.District_name,
    //             'Assembly Name': a.Assembly_ORDER + '-' + a.Assembly_name,
    //             'Polling Station': a.Location,
    //             'Camera No': a.CameraNum,
    //             'Camera Location':a.psFlag == 1 ? 'IN' : 'OUT',
    //             'OfflineCam': a.CameraOnline ? 'Online' : 'Offline'
    //           });
    //         });

    //         this.excelService.exportAsExcelFile(excelList, 'Ahmednagar', excelColumns);
    //       }
    //       catch (error) {

    //       }
    //     } else {

    //       console.log('Dashborad API Response Code => ' + res.API_CODE + ' & Msg => ' + res.API_MSG);
    //     }
    //   });
    // } 
    // else {
    let exportData = [];
    let labelName = '';
    if (this.data.currentView == Navigationlevel.level_01) {
      exportData = this.districtList;
      labelName = 'District Name';
    } else if (this.data.currentView == Navigationlevel.level_02) {
      exportData = this.assemblyList;
      labelName = 'Assembly Name';
    } else if (this.data.currentView == Navigationlevel.level_03) {
      exportData = this.pollingStationList;
      labelName = 'Polling Station Name';
    }

    if (!exportData || exportData.length === 0) {
      this.alertService.info('No data available to export');
      return;
    }

    let excelColumns = [
      labelName,
      'Total Camera',
      'Online Camera',
      'Offline Camera',
      'Oncelive Camera'
    ];

    const excelList = [];
    exportData.forEach(a => {
      excelList.push({
        labelName: a.code + '-' + a.name,
        'TotalCam': a.TotalCam,
        'OnlineCam': a.OnlineCam,
        'OfflineCam': a.OfflineCam,
        'OnceliveCam': a.OnceliveCam
      });
    });

    this.excelService.exportAsExcelFile(excelList, labelName.replace('Name', 'List'), excelColumns);
    //  }




  }
  viewLiveVideo(dataField) {
    const dialogRef = this.dialog.open(PopupComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'full-screen-modal-custom', // Use a unique class name
      data: {
        selectedData: dataField,
      },
    });
  }

  ngOnDestroy(): void {
    //   let currentData = JSON.parse(localStorage.getItem('currentData'));
    //   let getbackfromlivestreaming = JSON.parse(localStorage.getItem('backfromlivestreaming'));
    //  // if()
    //   let backfromlivestreaming = {
    //     district_ID: 0,
    //     isBack: 'N',
    //     currentData: currentData
    //   }
    //   localStorage.setItem('backfromlivestreaming', JSON.stringify(backfromlivestreaming))

  }
}
