import { DOCUMENT } from '@angular/common';
import { PopupdialogComponent } from './../popupdialog/popupdialog.component';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ThirdPartyService } from 'src/app/services/thirdparty.service';
import { SharedService } from 'src/app/services/sharedService';
import { RoleType } from 'src/enum/roletype';
import { HttpClient } from '@aspnet/signalr';
import { Router } from '@angular/router';
import { UserSessionService } from 'src/app/services/usersession.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class PopupComponent implements OnInit {

  dynamicData: any = {};
  roleType: any;
  roleTypeId: any;
  selectedData: any = {};


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PopupdialogComponent>, private thirdPartyService: ThirdPartyService, public dialog: MatDialog,
    config: NgbModalConfig,
    private modalService: NgbModal, @Inject(DOCUMENT) private document: any, private userSessionService: UserSessionService,
    private dashboardService: DashboardService, private sharedService: SharedService, private router: Router) {
      debugger;
      try {
        let getRoleName = this.userSessionService.roleName();
        this.roleType = getRoleName.toUpperCase();
        this.roleTypeId = this.userSessionService.roleId();
        this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));

      } catch (error) {

      }
  }

  ngOnInit(): void {
    debugger;
    this.selectedData = this.data.selectedData;
    console.log(this.selectedData, "ad");
    if (!document.fullscreenElement) {
      // Enter fullscreen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      }
    } else {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }
  btnCancel(){
    this.dialogRef.close();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}
