import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserSessionService } from './usersession.service';
import { DataService } from './data.service';
import { UserSession } from '../models/usersession';

import * as jwtDecode from 'jwt-decode';
import * as momenttz from 'moment-timezone';

import 'rxjs/add/operator/map';
import { DashboardService } from './dashboard.service';
declare var require: any;
const timezone = require('src/assets/timezones.json');
import * as CryptoJS from "crypto-js";
import { API_ENDPOINTS } from 'src/enum/api-endpoint';
import { RoleType } from 'src/enum/roletype';

@Injectable()
export class AuthenticationService {

  private baseUrl = environment.apiBaseUrl;
  timeZones: any[];
  pageAccess = true;
  sessionData = new UserSession();

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private sessionService: UserSessionService, private dashboardService: DashboardService,
  ) {
    this.getTimeZones();
  }

  login(username: string, password: string) {
    debugger;
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
      });

    const timeZone = this.getBrowserTimeZone();
    const data = { username, password, timezone: timeZone, appType: 3 };
    return this.http.post<any>(this.baseUrl + '/api/token', data, { headers })
      .map(user => {
        if (user && user.access_token) {
          this.clearCachedMenu();
          const decodedToken = jwtDecode(user.access_token);
          this.sessionData.email = decodedToken['user.email'];
          this.sessionData.mobileNumber = decodedToken['user.mobilenumber'];
          this.sessionData.authToken = user.access_token;
          this.sessionData.userId = decodedToken['user.id'];
          this.sessionData.roleId = decodedToken['user.roleId'];
          this.sessionData.roleName = decodedToken['user.roleName'];
          this.sessionData.userFullName = decodedToken['user.fullName'];
          this.sessionData.isDynamicPassword = decodedToken.referrence1 === 'True';
          this.sessionData.state = decodedToken['user.stateId'];
          this.sessionData.zone = decodedToken['user.zoneId'];
          this.sessionData.district = decodedToken['user.districtId'];
          this.sessionData.assembly = decodedToken['user.assemblyConstitutionId'];
          // this.sessionData.regionId = decodedToken['user.regionId'];
          // this.sessionData.divisionId = decodedToken['user.divisionId'];
          // this.sessionData.subDivisionId = decodedToken['user.subDivisionId'];
          this.sessionData.languageType = parseInt(decodedToken['user.languagetype']);

          // const districtIds = decodedToken['user.districts'];
          // const assemblyIds = decodedToken['user.assemblys'];
          // this.sessionData.districtId = districtIds.split(',');
          // this.sessionData.assemblyId = assemblyIds.split(',');
          this.sessionService.create(this.sessionData);
          try {
            let data = JSON.parse(localStorage.getItem('projectConfig'));
            data.zoneId = parseInt(decodedToken['user.zoneId']);
            data.stateId = parseInt(decodedToken['user.stateId']);
            data.districtId = parseInt(decodedToken['user.districtId']);
            data.assemblyConstitutionId = parseInt(decodedToken['user.assemblyConstitutionId']);
            data.parliamentConstitutionId = parseInt(decodedToken['user.parliamentConstitutionId']);
            data.LUFlag = parseInt(decodedToken['user.LUFlag']);
            data.isAllowed = false;
            data.isBtnApproval = false;
            localStorage.setItem('projectConfig', JSON.stringify(data));
            if (data.IsAllowedDEOBaseURL != "") {
              let ids = data.IsAllowedDEOBaseURL.split(",");
              ids.forEach(el => {
                if (el == decodedToken['user.roleId']) {
                  data.isAllowed = true;
                  data.label_01 = "District";
                }
              });
            }
            localStorage.setItem('projectConfig', JSON.stringify(data));
            if (data.isBtnApprovalds != "") {
              let getIsBtnApprovalIds = data.isBtnApprovalds.split(",");
              getIsBtnApprovalIds.forEach(el => {
                if (el == decodedToken['user.roleId']) {
                  data.isBtnApproval = true;
                }
              });
            }
            localStorage.setItem('projectConfig', JSON.stringify(data));

           

          } catch (error) {

          }

        }
        return user;
      });
  }

  isAuthenticated() {
    return !!this.sessionService.userId() && !!this.sessionService.authToken();
  }

  isPageAccessAvailable(pageUrl, pageTitle) {

    const rolePages = this.sessionService.getLocalStorageWithKey('role') ? this.sessionService.getLocalStorageWithKey('role') : '[]';
    const pages = JSON.parse(rolePages);
    const paths = ['/unauthorized', '/dashboard'];
    if (pages && pages.length > 0) {
      pages.forEach(field => {
        if (field.path) {
          paths.push(field.path);
          paths.push(field.title);
        }

        if (field.submenu && field.submenu.length > 0) {
          field.submenu.forEach(field1 => {
            if (field1.path) {
              paths.push(field1.path);
              paths.push(field1.title);
              if (field1.path === '/servicehosts/1' || field1.path === '/servicehosts/2') {
                paths.push('Test');
              }
              if (field1.path === '/beneficiaries/1' || field1.path === '/beneficiaries/2') {
                paths.push('beneficiaries');
              }
              if (field1.path === '/hosters/1' || field1.path === '/hosters/2') {
                paths.push('Hosters');
              }


            }
          });

        }
      });
      this.pageAccess = (paths.indexOf(pageUrl) >= 0) || (paths.indexOf(pageTitle) >= 0) ? true : false;
    }
    return this.pageAccess;
  }
  hasRequiredPermission(permission) {
    for (let i = 0; i < permission.length; i++) {
      if (permission[i] === this.sessionService.roleId()) {
        return true;
      }
    }
    return false;
  }

  getTimeZones() {
    this.timeZones = timezone.timeZone;
  }

  getBrowserTimeZone(): string {
    const zoneName = momenttz.tz.guess();
    const temptimezone = momenttz.tz(zoneName).zoneAbbr();
    const filterZone = this.timeZones.find(i => i.abbr === temptimezone);
    if (filterZone) {
      return filterZone.value;
    }
    return '';
  }

  clearCachedMenu() {
    this.dataService.clearCache();
  }

  clearSession() {
    this.sessionService.destroy();
    this.clearCachedMenu();
  }
  getProjectConfig() {
    let dynamicData: any = {};
    this.http.get(this.baseUrl + API_ENDPOINTS.ProjectConfig).subscribe((res: any) => {
      res.forEach(element => {
        if (element.key.toLowerCase() == 'ParliamentDistrictLabel'.toLowerCase()) {
          dynamicData.label_01 = element.value;
        } else if (element.key.toLowerCase() == 'AssemblyLabel'.toLowerCase()) {
          dynamicData.label_02 = element.value;
        } else if (element.key.toLowerCase() == 'PollingStationLabel'.toLowerCase()) {
          dynamicData.label_03 = element.value;
        } else if (element.key.toLowerCase() == 'ProjectState'.toLowerCase()) {
          dynamicData.projectState = element.value;
        } else if (element.key.toLowerCase() == 'ProjectBackground'.toLowerCase()) {
          dynamicData.projectBackground = element.value;
        } else if (element.key.toLowerCase() == 'ProjectTitle'.toLowerCase()) {
          dynamicData.projectTitle = element.value;
        } else if (element.key.toLowerCase() == 'ProjectLogo'.toLowerCase()) {
          dynamicData.projectLogo = element.value;
        } else if (element.key.toLowerCase() == 'CameraApiKey'.toLowerCase()) {
          dynamicData.API_Key = this.decrypt(element.value);
        } else if (element.key.toLowerCase() == 'CameraApiUser'.toLowerCase()) {
          dynamicData.webUser = this.decrypt(element.value);
        } else if (element.key.toLowerCase() == 'CameraApiProjectId'.toLowerCase()) {
          let webid = this.decrypt(element.value);
          dynamicData.webProId = parseInt(webid);
        } else if (element.key.toLowerCase() == 'CameraBaseApi'.toLowerCase()) {
          dynamicData.baseAPI_URL = this.decrypt(element.value);
        } else if (element.key.toLowerCase() == 'ProjectRightIcon'.toLowerCase()) {
          dynamicData.projectRightIcon = element.value;
        } else if (element.key.toLowerCase() == 'IFrameUrl'.toLowerCase()) {
          dynamicData.iframeURL = element.value != "" ? this.decrypt(element.value) : "";
          // console.log('dynamicData.iframeURL',dynamicData.iframeURL)
        } else if (element.key.toLowerCase() == 'ProjectSubTitle'.toLowerCase()) {
          dynamicData.projectSubTitle = element.value;
        } else if (element.key.toLowerCase() == 'LocationName'.toLowerCase()) {
          dynamicData.locationName = element.value;
        } else if (element.key.toLowerCase() == 'GridColumnName'.toLowerCase()) {
          dynamicData.gridColumnName = element.value;
        } else if (element.key.toLowerCase() == 'IsAllowedDEOBaseURL'.toLowerCase()) {
          dynamicData.IsAllowedDEOBaseURL = element.value;
        } else if (element.key.toLowerCase() == 'UnifiedBaseApi'.toLowerCase()) {
          dynamicData.UnifiedBaseApi = this.decrypt(element.value);
        } else if (element.key.toLowerCase() == 'UnifiedApiKey'.toLowerCase()) {
          dynamicData.UnifiedApiKey = this.decrypt(element.value);
        } else if (element.key.toLowerCase() == 'IsCamLocationFilter'.toLowerCase()) {
          dynamicData.isCamLocationFilter = parseInt(element.value);
        } else if (element.key.toLowerCase() == 'IsBoothCategory'.toLowerCase()) {
          dynamicData.isBoothCategory = parseInt(element.value);
        } else if (element.key.toLowerCase() == 'IsBuildVersion'.toLowerCase()) {
          dynamicData.IsBuildVersion = element.value;
          localStorage.setItem('isBuildVersion', element.value)
        } else if (element.key.toLowerCase() == 'IsBtnApproval'.toLowerCase()) {
          dynamicData.isBtnApprovalds = element.value;
        } else if (element.key.toLowerCase() == 'PSLableConfig'.toLowerCase()) {
          dynamicData.PSLableConfig = parseInt(element.value);
        }
      });
      return localStorage.setItem('projectConfig', JSON.stringify(dynamicData));

    })

  }
  decrypt(value) {
    let skey = "qw12er34ty56ui78";
    let siv = "12er34ty56ui78qw";
    var key = CryptoJS.enc.Utf8.parse(skey);
    var iv = CryptoJS.enc.Utf8.parse(siv);
    let API_KEY = CryptoJS.AES.decrypt(value, key, {
      keySize: 128,
      BlockSize: 128,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return API_KEY.toString(CryptoJS.enc.Utf8)
  }
  logOut() {
    this.clearCachedMenu();
    this.sessionService.destroy();
    localStorage.clear();
    this.getProjectConfig();
  }
}
