import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphanumericOnly]'
})
export class AlphanumericOnlyDirective {

  constructor() { }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^a-zA-Z0-9]/g, ''); // Allow only alphanumeric characters
  }
}
