import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { UserSessionService } from './usersession.service';
import { RoleType } from 'src/enum/roletype';
import { API_Details } from 'src/enum/api-details.enum';

@Injectable()
export class DataService {

  private baseUrl = environment.apiBaseUrl;
  private thirdPartyBaseURL: string = '';
  private cache: any = {};
  dynamicData: any = {};
  constructor(private http: HttpClient, private userSessionService: UserSessionService) {
  }

  getData(route, refresh) {
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else { // no cached data or refresh requested
      return this.http.get<any>(this.baseUrl + route).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  }
  getDataUnified(route, refresh) {
    debugger;
    let thirdPartyBaseURL = '';
    try {
      let roleTypeId = this.userSessionService.roleId();
      this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));
      let configURL = API_Details.Unified;
      thirdPartyBaseURL = this.dynamicData.UnifiedBaseApi + configURL;
      console.log(this.thirdPartyBaseURL)
    } catch (error) {

    }
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else { // no cached data or refresh requested
      return this.http.get<any>(thirdPartyBaseURL + route).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  }
  getWeatherData(route, refresh) {
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else { // no cached data or refresh requested
      return this.http.get<any>(route).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  }

  getDataWithParams(route, params, refresh) {
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else { // no cached data or refresh requested
      return this.http.get<any>(this.baseUrl + route, { params: params }).map(response => {
        this.cache[route] = response;
        return response;
      });
    }
  }

  getRecord(route) {
    return this.http.get<any>(this.baseUrl + route);
  }

  getRecordWithParams(route, params) {
    return this.http.get<any>(this.baseUrl + route, { params: params });
  }

  post(route, data) {
    return this.http.post<any>(this.baseUrl + route, data);
  }
  thirdPartyPostMethod(route, data) {
    try {
      let roleTypeId = this.userSessionService.roleId();
      this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));
      let configURL = this.dynamicData.isAllowed ? API_Details.DEO_URL : API_Details.BASE_URL;
      this.thirdPartyBaseURL = this.dynamicData.baseAPI_URL + configURL;
      console.log(this.thirdPartyBaseURL)
    } catch (error) {

    }

    return this.http.post<any>(this.thirdPartyBaseURL + route, data);
  }
  delete(route) {
    return this.http.delete(this.baseUrl + route).map(response => {
      return response;
    });
  }

  getReport(route) {
    return this.http.get(this.baseUrl + route, { responseType: 'blob' });
  }

  getExternalData(route) {
    return this.http.get<any>(route).map(response => {
      return response;
    });
  }

  dataForRouteIsCached(route, refresh) {
    return this.cache[route] && (refresh === false || refresh === undefined);
  }

  clearCache() {
    this.cache = {};
  }

  clearRouteCache(route) {
    this.cache[route] = null;
  }


}
