import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';

const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'dispatch',
    loadChildren: () => import('./views/pages/packingentry/packingentry.module').then(m => m.PackingentryModule)
  },
  {
    path: 'OperatorRegistration',
    loadChildren: () => import('./views/pages/operator-registration/operator-registration.module').then(m => m.OperatorRegistrationModule)
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/components/sharing.module').then(m => m.SharingModule)
      },
      {
        path: 'livestreamingview',
        loadChildren: () => import('./views/pages/livestreaming/livestreaming.module').then(m => m.LivestreamingModule)
      },
      {
        path: 'cameradetails',
        loadChildren: () => import('./views/pages/camera-details/camera-details.module').then(m => m.CameraDetailsModule)
      },
      {
        path: 'projectConfig',
        loadChildren: () => import('./views/pages/project-config/project-config.module').then(m => m.ProjectConfigModule)
      },
      {
        path: 'masterDataSync',
        loadChildren: () => import('./views/pages/master-data-sync/master-data-sync.module').then(m => m.MasterDataSyncModule)
      },
      {
        path: 'userManagement',
        loadChildren: () => import('./views/pages/usermanagement/usermanagement.module').then(m => m.UsermanagementModule)
      },
      {
        path: 'operatorManagement',
        loadChildren: () => import('./views/pages/operator-mapping/operator-mapping.module').then(m => m.OperatorMappingModule)
      },
      {
        path: 'supervisorManagement',
        loadChildren: () => import('./views/pages/supervisor-mapping/supervisor-mapping.module').then(m => m.SupervisorMappingModule)
      },
      {
        path: 'checkpostApprove',
        loadChildren: () => import('./views/pages/checkpost-approve/checkpost-approve.module').then(m => m.CheckpostApproveModule)
      },
      {
        path: 'networkStatus',
        loadChildren: () => import('./views/pages/network-status/network-status.module').then(m => m.NetworkStatusModule)
      },
      {
        path: 'mapview',
        loadChildren: () => import('./views/pages/mapview-turnout/mapview-turnout.module').then(m => m.MapviewTurnoutModule)
      },
      // {
      //   path: 'mapview',
      //   loadChildren: () => import('./views/pages/mapview/mapview.module').then(m => m.MapviewModule)
      // },
      // {
      //   path: 'unified',
      //   loadChildren: () => import('./views/pages/unifieddashboard/unifieddashboard.module').then(m => m.UnifieddashboardModule)
      // },
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      // },
      // {
      //   path: 'dashboard/:id',
      //   loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      // },
      // {
      //   path: 'multiplevehicletrack',
      //   loadChildren: () => import('./views/pages/tracking/multipletracking/multipletracking.module').then(m => m.MultipletrackingModule)
      // },
      // { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '', redirectTo: 'Dashboard', pathMatch: 'full' },
      //  { path: '', component: DashboardsummaryComponent },




      {
        path: 'profile/:id/:actionInfo',
        loadChildren: () => import('./views/pages/profile/profile/profile.module').then(m => m.ProfileModule)
      },

      {
        path: 'fuelreport',
        loadChildren: () => import('./views/pages/reports/fuelreport/fuelreport..module').then(m => m.FuelreportModule)
      },
      {
        path: 'packingentry',
        loadChildren: () => import('./views/pages/packingentry/packingentry.module').then(m => m.PackingentryModule)
      },
      {
        path: 'Returndevices',
        loadChildren: () => import('./views/pages/packingentry/returnform/returnform.module').then(m => m.ReturndeviceModule)
      },
      {
        path: 'DeviceStatus',
        loadChildren: () => import('./views/pages/packingentry/devicesstatus/devicestatus.module').then(m => m.DevicestatusModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./views/pages/user/user.module').then(m => m.UserModule)
      },
      //  {
      //   path:'deviceinstallation',
      //   loadChildren:() => import('./views/pages/deviceinstallation/deviceinstallation.module').then(m => m.DeviceinstallationModule)
      //  },
      {
        path: 'deviceinstallation',
        loadChildren: () => import('./views/pages/device-approval/device-approval.module').then(m => m.DeviceApprovalModule)
      },
      {
        path: 'cameraInstalltionDashboard',
        loadChildren: () => import('./views/pages/camera-installtion-dashboard/camera-installtion-dashboard.module').then(m => m.CameraInstalltionDashboardModule)
      },
      //  {
      //   path:'operatormanagement',
      //   loadChildren:() => import('./views/pages/operatordetails/operatordetails.module').then(m => m.OperatordetailsModule)
      //  }, 

      {
        path: 'adddevices',
        loadChildren: () => import('./views/pages/device/device.module').then(m => m.DeviceModule)
      },
      {
        path: 'pollingdviceinstall',
        loadChildren: () => import('./views/pages/pollinginstallationdevice/pollinginstallationdevice.module').then(m => m.PollinginstallationdeviceModule)
      },
      {
        path: 'attendance',
        loadChildren: () => import('./views/pages/attendance/attendance.module').then(m => m.AttendanceModule)
      },
      {
        path: 'complaint',
        loadChildren: () => import('./views/pages/complaints/complaints.module').then(m => m.ComplaintsModule)
      },
      {
        path: 'reportvisit',
        loadChildren: () => import('./views/pages/reportvisit/reportvisit.module').then(m => m.ReportvisitModule)
      },
      {
        path: 'attendanceDashboard',
        loadChildren: () => import('./views/pages/attendance-dashboard/attendance-dashboard.module').then(m => m.AttendanceDashboardModule)
      },
      {
        path: 'support',
        loadChildren: () => import('./views/pages/supervisor-support/supervisor-support.module').then(m => m.SupervisorSupportModule)
      },
      {
        path: 'generateBox',
        loadChildren: () => import('./views/pages/generateboxnumber/generateboxnumber.module').then(m => m.GenerateboxnumberModule)
      },
      {
        path: 'packingdetailslist',
        loadChildren: () => import('./views/pages/packingentry/get-packing-list/get-packing-list.module').then(m => m.GetPackingListModule)
      }

    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
