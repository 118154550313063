import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { environment } from 'src/environments/environment';
import { GlobalProvider } from './services/global';
//import { SwUpdate } from '@angular/service-worker';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import swal from 'sweetalert2';
import { API_ENDPOINTS } from 'src/enum/api-endpoint';
import { HttpClient } from '@angular/common/http';
import { UserSessionService } from './services/usersession.service';
// @HostListener("window:onbeforeunload", ["$event"])
//import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'AED';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;
  countDown: Subscription;
  counter = 31;
  private baseUrl = environment.apiBaseUrl;
 // favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  constructor(dateTimeAdapter: DateTimeAdapter<any>,
    private router: Router, public loaderOFF: GlobalProvider,
    private http: HttpClient,
    private userSessionService: UserSessionService
    //private swUpdate: SwUpdate
    //, private swUpdate: SwUpdate
  ) {
   // this.favIcon.href = 'favicon-TN.png';
    dateTimeAdapter.setLocale('en-in');
    document.title = environment.title;

    // if (environment.buildVersion != currentVersion) {

    //   const title = 'New Version';
    //   const txt = '';
    //   const Yes = 'Confirm';
    //   const No = 'Cancel';
    //   swal.fire({
    //     title,
    //     text: txt,
    //     icon: 'warning',
    //     showCancelButton: false,
    //     confirmButtonColor: '#3085d6',
    //     cancelButtonColor: '#d33',
    //     confirmButtonText: Yes,
    //     cancelButtonText: No,
    //   }).then((result) => {
    //     localStorage.setItem('buildVersion', environment.buildVersion);
    //     window.location.reload()
    //   })

    // }
    // this.swUpdate.available.subscribe(() => {
    //   if (confirm('A new version is available. Reload to update?')) {
    //     window.location.reload();
    //   }
    // });
  }

  ngOnInit(): void {
    // this.swUpdate.available.subscribe(event => {
    //   console.log('A newer version is now available. Refresh the page now to update the cache');
    // });
    // this.swUpdate.checkForUpdate()
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus = 'online';
      location.reload();
      alert(this.connectionStatusMessage)
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus = 'offline';
      alert(this.connectionStatusMessage)
    }));
    if (environment.production) {
      if (location.protocol !== 'https:') {
        location.replace(`https:${location.href.substring(location.protocol.length)}`);
      }
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url === '/tripreport' || e.url === '/tripsimulationreport' || e.url.startsWith('/tripsimulationreport')
          || e.url.startsWith('/serviceschedules') || e.url.startsWith('/targets') || e.url.startsWith('/tariffs')
          || e.url.startsWith('/regions') || e.url.startsWith('/regions') || e.url.startsWith('/divisions')
          || e.url.startsWith('/subdivisions') || e.url.startsWith('/consumables') || e.url.startsWith('/workshops')
          || e.url.startsWith('/providers')
          || e.url.startsWith('/users/')
          || e.url.startsWith('/vehiclelogreport')
          || e.url.startsWith('/devices/')
          || e.url.startsWith('/vehicles/')
          || e.url.startsWith('/implements/')
          || e.url.startsWith('/drivers/')
          || e.url.startsWith('/fuellog/')
          || e.url.startsWith('/tripentries/')
          || e.url.startsWith('/servicelog/')
          || e.url.startsWith('/consumableentries/')
          || e.url.startsWith('/expenseentries/')) {
          document.body.classList.add('removeglobalfilter');
        } else {
          document.body.classList.remove('removeglobalfilter');
        }

        //document.body.classList.remove('showfullmap');
        ///document.body.classList.remove('showhalfmap');
      }
    });
   // this.startApiCalls();


  }

  clearAllData() {
    // this.authService.logOut();
  }
  startApiCalls(): void {
    this.countDown = timer(0, 1000).subscribe(() => {
      --this.counter;
      if (this.counter === 0) {
        this.counter = 60;
        let isLoggedin = localStorage.getItem('isLoggedin')
        if (isLoggedin != null && isLoggedin) {
          this.getProjectConfig();
        }

      }
    });
  }
  getProjectConfig() {
    let dynamicData: any = {};
    this.http.get(this.baseUrl + API_ENDPOINTS.ProjectConfig).subscribe((res: any) => {
      try {
        // let roleTypeId = this.userSessionService.roleId();
        // let data = JSON.parse(localStorage.getItem('projectConfig'));
        // let getIsBtnApprovalds = res.filter(item => item.key.toLocaleLowerCase() == 'IsBtnApproval'.toLocaleLowerCase());
        // if (getIsBtnApprovalds.length > 0) {
        //   getIsBtnApprovalds = getIsBtnApprovalds[0].value;
        //   data.isBtnApprovalds = getIsBtnApprovalds;
        // } else {
        //   getIsBtnApprovalds = '';
        // }
        // if (data.isBtnApprovalds != "") {
        //   let ids = getIsBtnApprovalds.isBtnApprovalds.split(",");
        //   ids.forEach(el => {
        //     if (el == roleTypeId) {
        //       data.isBtnApproval = true;
        //     }
        //   });
        // }
        // localStorage.setItem('projectConfig', JSON.stringify(data));
        let isCurrentNewVersion = res.filter(item => item.key.toLocaleLowerCase() == 'IsBuildVersion'.toLocaleLowerCase());
        if (isCurrentNewVersion.length > 0) {
          let previousBuildVersion: any = localStorage.getItem('isBuildVersion');
          if (isCurrentNewVersion[0].value != previousBuildVersion) {
            localStorage.setItem('isBuildVersion', isCurrentNewVersion[0].value)
            this.reloadPage(5, 5000); // Reload 3 times with 2-second delay
          }
        }
      } catch (error) {

      }


    })

  }
  reloadPage(numTimes: number, delayMs: number) {
    this.reloadRecursive(numTimes, delayMs, 0);
  }
  reloadRecursive(numTimes: number, delayMs: number, count: number): void {
    if (count < numTimes) {
      console.log(`Reloading page ${count + 1} of ${numTimes}`);
      setTimeout(() => {
        window.location.reload();
        this.reloadRecursive(numTimes, delayMs, count + 1);
      }, delayMs);
    } else {
      console.log(`Reload completed ${numTimes} times`);
    }
  }
}

