import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ThirdPartyService } from 'src/app/services/thirdparty.service';
import { API_ENDPOINTS } from 'src/enum/api-endpoint';
import { RESPONSE_CODE } from 'src/enum/responseCode';
import { Subscription, interval, timer, fromEvent, Observable, } from 'rxjs';
import { SharedService } from 'src/app/services/sharedService';
import { Navigationlevel } from 'src/enum/navigationlevel.enum';
import { UserSessionService } from 'src/app/services/usersession.service';
import { RoleTypeDesc } from 'src/enum/roletypeDesc';
import { DashboardService } from 'src/app/services/dashboard.service';
import { GlobalProvider } from 'src/app/services/global';
import { RoleType } from 'src/enum/roletype';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-unifieddashboard',
  templateUrl: './unifieddashboard.component.html',
  styleUrls: ['./unifieddashboard.component.scss']
})
export class UnifieddashboardComponent implements OnInit {

  data: any = [];
  districtList: any = [];
  assemblyList: any = [];
  boothList: any = [];
  district_ID: number = 0;
  assembly_ID: number = 0;
  liveList: boolean = false;
  webDataID: number = 0;
  pageNumber: number = 1;
  dashboardCardText:string = environment.dashboardCardText;
  colorCodes: any = ["#264D59",
    "#43978D",
    "gray",
    "#F9AD6A",
    "#D46C4E"]
  //colorCodes:any=["#455054","#308695","#D45769","#E69D45","#D4CFC9"]
  cardList: any = [{
    count: 0,
    key: "Total Camera",
    img: "../assets/dashboard/TotalCamera.png",
    colorCode: this.colorCodes[0]
  },
  {
    count: 0,
    key: "Online Camera",
    img: "../assets/dashboard/OnlineCamera.png",
    colorCode: this.colorCodes[1]
  },
  {
    count: 0,
    key: "Offline Camera",
    img: "../assets/dashboard/OfflineCamera.png",
    colorCode: this.colorCodes[2]
  },
  {
    count: 0,
    key: this.dashboardCardText,
    img: "../assets/dashboard/OnceLive.png",
    colorCode: this.colorCodes[3]
  },
  {
    count: 0,
    key: "Last 60 Mins Camera",
    img: "../assets/dashboard/Hours.png",
    colorCode: this.colorCodes[4]
  }];
  private intervalTime = 10000;
  private apiSubscription: Subscription;
  currentViewData: any = [];
  chartsData: any = { assembly_ID: 0, list: [] };
  cardDetailsData: any = {};
  i: number = 0;
  isViewCardDetails: boolean = false;
  assemblyListDashboardData: any = [];
  districtDashboardData: any = [];
  districtVal: any = 0;
  assemblyVal: any = 0;
  dynamicData: any = {};
  label: any = [];
  timeoutId: any;
  project: any = [];
  Navigationlevel: any = Navigationlevel;
  countDown: Subscription;
  counter = 61;
  zoneId: number = 0;
  stateId: number = 0;
  isDistrictDisabled: boolean = false;
  isAssemblyDisabled: boolean = false;
  roleType: string = '';
  isHoursData: string = 'N';
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];

  connectionStatusMessage: string;
  connectionStatus: string;
  isFirstTimeLoad: boolean = true;
  roleTypeId: number = 0;
  RoleType: any = RoleType;
  isListView: boolean = false;
  isBackBtn: boolean = false;
  constructor(private thirdPartyService: ThirdPartyService, private sharedService: SharedService,
    private userSessionService: UserSessionService, private dashboardService: DashboardService,
    public loaderOFF: GlobalProvider) {
    try {
      this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));
      this.district_ID = this.dynamicData.parliamentConstitutionId;
      this.assembly_ID = this.dynamicData.assemblyConstitutionId;

      let getRoleName = this.userSessionService.roleName();
      this.roleType = getRoleName.toUpperCase();
      this.roleTypeId = this.userSessionService.roleId();
      this.onlineEvent = fromEvent(window, 'online');
      this.offlineEvent = fromEvent(window, 'offline');

      this.subscriptions.push(this.onlineEvent.subscribe(e => {
        this.connectionStatusMessage = 'Back to online';
        this.connectionStatus = 'online';

      }));

      this.subscriptions.push(this.offlineEvent.subscribe(e => {
        this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
        this.connectionStatus = 'offline';

      }));
    } catch (error) {

    }
    this.loaderOFF.loaderShow = true;

  }

  ngOnInit(): void {
    localStorage.setItem('unifieddashboard','Y');
    let setData = {
      district_ID: this.district_ID,
      assembly_ID: this.assembly_ID,
      currentView: Navigationlevel.level_01,
      breadcrumb: '',
      pageTitle: this.dynamicData?.label_01
    }
    localStorage.setItem('currentData', JSON.stringify(setData))
    this.currentViewData = JSON.parse(localStorage.getItem('currentData'))
    const getHours = new Date().getHours();
    localStorage.setItem('currentHour', getHours.toString());
    localStorage.setItem('hoursData', null);
    // this.getDashboradList();
    this.getDropdownList();
    // this.startApiCalls();
    this.districtList.unshift({ District_ID: 0, District_name: "ALL" });
    this.isListView = false;
    this.isBackBtn = false
    let event = { checked: false }
    //  this.openListView(event)
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
    this.stopApiCalls();
  }


  onDistrictChange(e) {
    this.district_ID = e;
    this.assembly_ID = 0;
    this.isHoursData = 'N';
    this.dashboardService.getDistrictIdByAssemblyList(this.district_ID, true).subscribe(res => {
      this.assemblyList = res;
      this.assemblyList.unshift({ key: 0, regionalValue: "ALL" });
      this.getDashboradList();
    })
    // this.getDropdownList();

  }

  setSelectedIdByDashboard() {
    try {

      let localData = this.data;
      let res = { TotalCam: 0, OnlineCam: 0, OfflineCam: 0, OnceliveCam: 0, LasthourCam: 0 };
      let filterData = [];
      let dynamicArrayList = this.districtList;
      let filterList = [];
      let filterType = 'DistrictID';
      if (this.district_ID > 0 && this.assembly_ID == 0) {
        let districtIdByFilterData = dynamicArrayList;
        dynamicArrayList = districtIdByFilterData.filter(j => j.key == this.district_ID);
        filterType = 'DistrictID';
      }
      else if (this.district_ID > 0 && this.assembly_ID > 0) {
        dynamicArrayList = this.assemblyList;
        let assemblyIdByFilterData = dynamicArrayList;
        dynamicArrayList = assemblyIdByFilterData.filter(j => j.key == this.assembly_ID);
        filterType = 'AssemblyID';
      }


      dynamicArrayList.forEach(element => {
        filterList = localData.dataList.filter(j => j[filterType] == element.key);
        if (filterList.length > 0) {
          filterData.push(...filterList)
        }
      });

      filterData.forEach(element => {
        res['TotalCam'] += element.TotalCam;
        res['OnlineCam'] += element.OnlineCam;
        res['OfflineCam'] += element.OfflineCam;
        res['OnceliveCam'] += element.OnceliveCam;
        res['LasthourCam'] += element.LasthourCam;
      });
      res['dataList'] = filterData;

      this.cardList = [];
      this.cardList.push({
        count: res['TotalCam'],
        key: "Total Camera",
        img: "../assets/dashboard/TotalCamera.png",
        colorCode: this.colorCodes[0]
      },
        {
          count: res['OnlineCam'],
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: res['OfflineCam'],
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: res['OnceliveCam'],
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: res['LasthourCam'],
          key: "Last 60 Mins Camera",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }
      )

      let currentHour = localStorage.getItem('currentHour');
      if (this.isHoursData == 'N') {
        let payload = {};
        let URL = API_ENDPOINTS.CamHourList;
        payload = {
          "WebUSER": this.dynamicData?.webUser,
          "WebproID": this.dynamicData?.webProId,
          "District_ID": this.district_ID,
          "Assembly_ID": this.assembly_ID,
          "WebDataID": 0
        }
        if (this.isFirstTimeLoad) {
          this.isFirstTimeLoad = false;
          this.loaderOFF.loaderShow = true;
        }

        this.thirdPartyService.getDataUnified(URL, true).subscribe(response => {
          if (response.API_CODE == RESPONSE_CODE.SUCCESS) {
            localStorage.setItem('hoursData', JSON.stringify(response.HourList));
            this.isHoursData = 'Y'
            res['HourList'] = response.HourList;
            this.chartsData = {
              assembly_ID: this.assembly_ID,
              list: res
            }
            this.loaderOFF.loaderShow = false;
          } else {
            this.loaderOFF.loaderShow = false;
            console.log('Dashborad API Response Code => ' + response.API_CODE + ' & Msg => ' + response.API_MSG);
            res['HourList'] = [];
            this.chartsData = {
              assembly_ID: this.assembly_ID,
              list: res
            }
          }
        },
          err => {
            this.loaderOFF.loaderShow = false;
            res['HourList'] = [];
            this.chartsData = {
              assembly_ID: this.assembly_ID,
              list: res
            }
          });
      } else {
        let getHours = new Date().getHours()
        if (getHours == parseInt(currentHour)) {
          res['HourList'] = JSON.parse(localStorage.getItem('hoursData'));
          this.chartsData = {
            assembly_ID: this.assembly_ID,
            list: res
          }
        } else {
          this.isHoursData = 'N';
          let payload = {};
          let URL = API_ENDPOINTS.CamHourList;
          payload = {
            "WebUSER": this.dynamicData?.webUser,
            "WebproID": this.dynamicData?.webProId,
            "District_ID": this.district_ID,
            "Assembly_ID": this.assembly_ID,
            "WebDataID": 0
          }
          if (this.isFirstTimeLoad) {
            this.isFirstTimeLoad = false;
            this.loaderOFF.loaderShow = true;
          }
          this.thirdPartyService.postMethod(URL, payload).subscribe(response => {
            if (response.API_CODE == RESPONSE_CODE.SUCCESS) {
              localStorage.setItem('hoursData', JSON.stringify(response.HourList));
              this.isHoursData = 'Y';
              this.loaderOFF.loaderShow = false;
              const getHours = new Date().getHours();
              localStorage.setItem('currentHour', getHours.toString());
              res['HourList'] = response.HourList;
              this.chartsData = {
                assembly_ID: this.assembly_ID,
                list: res
              }
            } else {
              this.loaderOFF.loaderShow = false;
              res['HourList'] = [];
              this.chartsData = {
                assembly_ID: this.assembly_ID,
                list: res
              }
              console.log('Dashborad API Response Code => ' + response.API_CODE + ' & Msg => ' + response.API_MSG);
            }
          },
            err => {
              res['HourList'] = [];
              this.chartsData = {
                assembly_ID: this.assembly_ID,
                list: res
              }
              this.loaderOFF.loaderShow = false;
            });
        }

      }
      this.connectionStatus = 'online';
    } catch (error) {

    }
  }

  onAssemblyChange(e) {
    this.assembly_ID = e;
    this.isHoursData = 'N';
    this.getDashboradList();
  }
  openListView(event) {
    if (event.checked) {
      this.isBackBtn = true;
      this.isHoursData = 'N';
      let setData = {
        district_ID: this.dynamicData.parliamentConstitutionId,
        assembly_ID: this.dynamicData.assemblyConstitutionId,
        currentView: Navigationlevel.level_01,
        breadcrumb: '',
        pageTitle: this.dynamicData?.label_01
      }
      this.cardDetailsData = {};
      this.currentViewData = setData;
      localStorage.setItem('currentData', JSON.stringify(setData))
      this.isViewCardDetails = !this.isViewCardDetails;
      this.district_ID = this.dynamicData.parliamentConstitutionId;
      this.assembly_ID = this.dynamicData.assemblyConstitutionId;
      this.getDashboradList();
      this.counter = 60;
      this.startApiCalls();
    } else {
      this.isBackBtn = false;
      let setData = {
        district_ID: this.district_ID,
        assembly_ID: this.assembly_ID,
        currentView: Navigationlevel.level_01,
        breadcrumb: '',
        pageTitle: this.dynamicData?.label_01
      }
      this.cardDetailsData = {};
      localStorage.setItem('currentData', JSON.stringify(setData));
      // localStorage.setItem('filterType','ALL');
      this.isViewCardDetails = !this.isViewCardDetails;
      this.counter = 60;
      this.stopApiCalls();
    }
  }
  private startApiCalls(): void {
    this.countDown = timer(0, 1000).subscribe(() => {
      --this.counter;
      if (this.counter === 0) {
        this.counter = 60;
        this.getDashboradList();
      }
    });
    // this.apiSubscription = interval(this.intervalTime).subscribe(() => {
    //   console.log('intervalTime', this.intervalTime)

    //   this.getDashboradList();
    // });
  }
  onMessageFromCardChild(message: string) {
    this.currentViewData = JSON.parse(localStorage.getItem('currentData'))
    this.isBackBtn = this.currentViewData.isBackBtn;
  }
  private stopApiCalls(): void {
    if (this.countDown) {
      this.countDown.unsubscribe();
      clearTimeout(this.timeoutId);
      console.log('2')
    }
  }
  getCamHourList(dataList) {
    this.thirdPartyService.getDataUnified(API_ENDPOINTS.CamHourList, true).subscribe(res => {
      if (res.API_CODE == RESPONSE_CODE.SUCCESS) {
        let send = [{
          HourList: res.HourList,
          TotalCam: dataList.TotalCam,
          OnlineCam: dataList['OnlineCam'],
          OfflineCam: dataList['OfflineCam'],
          OnceliveCam: dataList['OnceliveCam'],
          LasthourCam: dataList['LasthourCam'],
          dataList: [],
        }]


        this.chartsData = {
          assembly_ID: this.assembly_ID,
          list: send[0],

        }
        console.log(this.chartsData)
        this.loaderOFF.loaderShow = false;
        // this.setSelectedIdByDashboard();
      } else {
        this.loaderOFF.loaderShow = false;
        console.log('Dashborad API Response Code => ' + res.API_CODE + ' & Msg => ' + res.API_MSG);
        let send = [{
          HourList: [],
          TotalCam: dataList.TotalCam,
          OnlineCam: dataList['OnlineCam'],
          OfflineCam: dataList['OfflineCam'],
          OnceliveCam: dataList['OnceliveCam'],
          LasthourCam: dataList['LasthourCam'],
          dataList: [],
        }]


        this.chartsData = {
          assembly_ID: this.assembly_ID,
          list: send[0],

        }
        console.log(this.chartsData)
      }
    },
      err => {
        let send = [{
          HourList: [],
          TotalCam: dataList.TotalCam,
          OnlineCam: dataList['OnlineCam'],
          OfflineCam: dataList['OfflineCam'],
          OnceliveCam: dataList['OnceliveCam'],
          LasthourCam: dataList['LasthourCam'],
          dataList: [],
        }]


        this.chartsData = {
          assembly_ID: this.assembly_ID,
          list: send[0],

        }
        this.loaderOFF.loaderShow = false;

      });
  }
  getDashboradList() {
    let URL = API_ENDPOINTS.UserDashboard;
    this.thirdPartyService.getDataUnified(URL, true).subscribe(res => {
      if (res.API_CODE == RESPONSE_CODE.SUCCESS) {
        this.cardList = [{
          count: res.TotalCam,
          key: "Total Camera",
          img: "../assets/dashboard/TotalCamera.png",
          colorCode: this.colorCodes[0]
        },
        {
          count: res.OnlineCam,
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: res.OfflineCam,
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: res.OnceliveCam,
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: res.LasthourCam,
          key: "Last 60 Mins Camera",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }];
        //res['HourList'] = [];
        let dataList =
          { TotalCam: res['TotalCam'], OnlineCam: res['OnlineCam'], OfflineCam: res['OfflineCam'], OnceliveCam: res['OnceliveCam'], LasthourCam: res['LasthourCam'] }




        // this.chartsData = {
        //   assembly_ID: this.assembly_ID,
        //   list: res
        // }
        this.getCamHourList(dataList);
        // this.setSelectedIdByDashboard();
      } else {
        this.loaderOFF.loaderShow = false;
        console.log('Dashborad API Response Code => ' + res.API_CODE + ' & Msg => ' + res.API_MSG);
        this.cardList = [{
          count: 0,
          key: "Total Camera",
          img: "../assets/dashboard/TotalCamera.png",
          colorCode: this.colorCodes[0]
        },
        {
          count: 0,
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: 0,
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: 0,
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: 0,
          key: "Last 60 Mins Camera",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }];
      }
    },
      err => {
        this.loaderOFF.loaderShow = false;
        this.cardList = [{
          count: 0,
          key: "Total Camera",
          img: "../assets/dashboard/TotalCamera.png",
          colorCode: this.colorCodes[0]
        },
        {
          count: 0,
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: 0,
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: 0,
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: 0,
          key: "Last 60 Mins Camera",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }];
      });
  }


  getDropdownList() {

    let zoneId = this.dynamicData.zoneId
    this.dashboardService.getZoneIdByDistrictList(zoneId, true).subscribe(res => {
      this.districtList = res;
      this.districtList.unshift({ key: 0, regionalValue: "ALL" });
      this.assemblyList.unshift({ key: 0, regionalValue: "ALL" });
      let _this = this;
      if (_this.roleTypeId == _this.RoleType.ACAdmin || _this.roleTypeId == _this.RoleType.AssemblyControlCenter || _this.dynamicData.parliamentConstitutionId > 0 && _this.dynamicData.assemblyConstitutionId > 0) {
        this.isAssemblyDisabled = true;
        this.isDistrictDisabled = true;
        this.dashboardService.getDistrictIdByAssemblyList(this.district_ID, true).subscribe(res => {
          this.assemblyList = res;
          this.assemblyList.unshift({ key: 0, regionalValue: "ALL" });
          this.getDashboradList();
        })
      } else {
        if (_this.roleTypeId == _this.RoleType.DistrictAdmin || _this.roleTypeId == _this.RoleType.DistrictControlCenter || _this.dynamicData.parliamentConstitutionId > 0) {
          this.isDistrictDisabled = true;
          this.dashboardService.getDistrictIdByAssemblyList(this.district_ID, true).subscribe(res => {
            this.assemblyList = res;
            this.assemblyList.unshift({ key: 0, regionalValue: "ALL" });
            this.getDashboradList();
          })
        } else {
          this.getDashboradList();
        }

      }

    },
      err => {
        this.loaderOFF.loaderShow = false;
        this.cardList = [{
          count: 0,
          key: "Total Camera",
          img: "../assets/dashboard/TotalCamera.png",
          colorCode: this.colorCodes[0]
        },
        {
          count: 0,
          key: "Online Camera",
          img: "../assets/dashboard/OnlineCamera.png",
          colorCode: this.colorCodes[1]
        },
        {
          count: 0,
          key: "Offline Camera",
          img: "../assets/dashboard/OfflineCamera.png",
          colorCode: this.colorCodes[2]
        },
        {
          count: 0,
          key: this.dashboardCardText,
          img: "../assets/dashboard/OnceLive.png",
          colorCode: this.colorCodes[3]
        },
        {
          count: 0,
          key: "Last 60 Mins Camera",
          img: "../assets/dashboard/Hours.png",
          colorCode: this.colorCodes[4]
        }];
      })


    // let data = [
    //   {
    //     'route': API_ENDPOINTS.DistrictList,
    //     "payload": {
    //       "WebUSER": this.dynamicData?.webUser,
    //       "WebproID": this.dynamicData?.webProId
    //     }
    //   },
    //   {
    //     'route': API_ENDPOINTS.AssemblyList,
    //     "payload": {
    //       "WebUSER": this.dynamicData?.webUser,
    //       "WebproID": this.dynamicData?.webProId,
    //       "District_ID": this.district_ID
    //     }
    //   }
    // ]
    // this.thirdPartyService.forkJoinAPI(data).subscribe((forkJoinRespones: any) => {
    //   try {

    //     if (this.dynamicData.parliamentConstitutionId > 0) {
    //       this.isDistrictDisabled = true;
    //       this.district_ID = this.dynamicData.parliamentConstitutionId;
    //     }
    //     if (this.dynamicData.assemblyConstitutionId > 0) {
    //       this.isAssemblyDisabled = true;
    //       this.assembly_ID = this.dynamicData.assemblyConstitutionId;
    //     }
    //     if (forkJoinRespones.length > 0) {
    //       if (forkJoinRespones[0].API_CODE == RESPONSE_CODE.SUCCESS) {
    //         if (this.dynamicData.zoneId > 0 && this.roleType == RoleTypeDesc.AC_Coordinator.toLocaleUpperCase()) {
    //           this.districtList = []
    //           let ids = [101, 102, 103]
    //           ids.forEach(element => {
    //             let localData = forkJoinRespones[0].DistrictList.filter(l => l.District_ID == element);
    //             this.districtList.push(...localData);
    //           });
    //           this.districtList.unshift({ District_ID: 0, District_name: "ALL" })
    //         } else {
    //           this.districtList = forkJoinRespones[0].DistrictList;
    //           this.districtList.unshift({ District_ID: 0, District_name: "ALL" })
    //         }


    //       } else {
    //         this.districtList = [];
    //         console.log('District API Response Code => ' + forkJoinRespones[0].API_CODE + ' & Msg => ' + forkJoinRespones[0].API_MSG);
    //       }
    //       if (forkJoinRespones[1].API_CODE == RESPONSE_CODE.SUCCESS) {
    //         this.assemblyList = forkJoinRespones[1].AssemblyList;
    //         this.assemblyList.unshift({ Assembly_ID: 0, Assembly_name: "ALL" })
    //       } else {
    //         this.assemblyList = [];
    //         this.assemblyList.unshift({ Assembly_ID: 0, Assembly_name: "ALL" })
    //         console.log('Assembly API Response Code => ' + forkJoinRespones[1].API_CODE + ' & Msg => ' + forkJoinRespones[1].API_MSG);
    //       }
    //     }
    //   } catch (error) {

    //   }

    // })
  }
  btnStatus() {
    let setData = {
      district_ID: this.district_ID,
      assembly_ID: this.assembly_ID,
      currentView: Navigationlevel.level_01,
      breadcrumb: '',
      pageTitle: this.dynamicData?.label_01
    }
    this.cardDetailsData = {};
    localStorage.setItem('currentData', JSON.stringify(setData));
    // localStorage.setItem('filterType','ALL');
    this.isViewCardDetails = !this.isViewCardDetails;
    this.counter = 60;
    //this.isListView = true;
    this.stopApiCalls();
  }
  btnBack() {
    this.isHoursData = 'N';
    let data = JSON.parse(localStorage.getItem('currentData'))
    if (data.currentView == Navigationlevel.level_04) {
      data.currentView = Navigationlevel.level_03;
      let a = data.breadcrumb.split("|");
      let b = a[2].split("\n")
      data.breadcrumb = ' | ' + a[1] + ' | ' + b[1];
      data.pageTitle = this.dynamicData?.label_03
    }
    else if (data.currentView == Navigationlevel.level_03) {
      data.currentView = Navigationlevel.level_02;
      let a = data.breadcrumb.split("|");
      data.breadcrumb = ' | ' + a[0] + a[1];
      data.pageTitle = this.dynamicData?.label_02
    }
    else if (data.currentView == Navigationlevel.level_02) {
      let a = data.breadcrumb.split("|");
      data.breadcrumb = '';
      data.currentView = Navigationlevel.level_01;
      data.pageTitle = this.dynamicData?.label_01
      this.isBackBtn = false;
      this.isListView = false;
    } else if (data.currentView == Navigationlevel.level_01) {
      data.currentView = Navigationlevel.level_0;
      this.isViewCardDetails = !this.isViewCardDetails;
      this.district_ID = this.dynamicData.parliamentConstitutionId;
      this.assembly_ID = this.dynamicData.assemblyConstitutionId;
      this.isListView = false;
      this.isBackBtn = false;
      this.getDashboradList();
      this.counter = 60;
      // this.timeoutId = setTimeout(() => {
      this.startApiCalls();
      // }, this.intervalTime);
    }
    this.currentViewData = data;
    localStorage.setItem('currentData', JSON.stringify(data))
    this.cardDetailsData = {};
  }
  btnHome() {
    this.isHoursData = 'N';
    let setData = {
      district_ID: this.dynamicData.parliamentConstitutionId,
      assembly_ID: this.dynamicData.assemblyConstitutionId,
      currentView: Navigationlevel.level_01,
      breadcrumb: '',
      pageTitle: this.dynamicData?.label_01
    }
    this.cardDetailsData = {};
    this.currentViewData = setData;
    localStorage.setItem('currentData', JSON.stringify(setData))
    this.isViewCardDetails = !this.isViewCardDetails;
    this.district_ID = this.dynamicData.parliamentConstitutionId;
    this.assembly_ID = this.dynamicData.assemblyConstitutionId;
    this.counter = 60;
    this.isListView = false;
    this.isBackBtn = false;
    let event = { checked: false }
    this.openListView(event)
    this.getDashboradList();
    this.startApiCalls();
  }
  reset() {
    // let setData = {
    //   district_ID: this.dynamicData.parliamentConstitutionId,
    //   assembly_ID: this.dynamicData.assemblyConstitutionId,
    //   currentView: Navigationlevel.level_01,
    //   breadcrumb: '',
    //   pageTitle: this.dynamicData?.label_01
    // }
    // this.cardDetailsData = {};
    // this.currentViewData = setData;
    // localStorage.setItem('currentData', JSON.stringify(setData))
    // this.district_ID = this.dynamicData.parliamentConstitutionId;
    // this.assembly_ID = this.dynamicData.assemblyConstitutionId;
    this.getDashboradList();
    // this.counter = 60;
    // //   this.timeoutId = setTimeout(() => {
    // this.startApiCalls();
    // }, this.intervalTime);
  }
  refresh() {
    this.getDashboradList();
  }
}

