import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { API_ENDPOINTS } from 'src/enum/api-endpoint';
import { forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserSessionService } from './usersession.service';
import { RoleType } from 'src/enum/roletype';
import { API_Details } from 'src/enum/api-details.enum';
@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService {
  private baseUrl = environment.apiBaseUrl;
  private thirdPartyBaseURL = '';
  dynamicData: any = {};
  constructor(private dataService: DataService, private http: HttpClient,private userSessionService: UserSessionService) {
    try {
      this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));
      let configURL = this.dynamicData.isAllowed ? API_Details.DEO_URL: API_Details.BASE_URL;
      this.thirdPartyBaseURL = this.dynamicData.baseAPI_URL + configURL;
    } catch (error) {

    }

  }

  postMethod(URL, data: any) {
    return this.dataService.thirdPartyPostMethod(URL, data).map(response => {
      this.dataService.clearRouteCache(API_ENDPOINTS.UserDashboard);
      return response;
    });
  }
  getDataUnified(URL, data: any) {
    return this.dataService.getDataUnified(URL, data).map(response => {
      this.dataService.clearRouteCache(API_ENDPOINTS.UserDashboard);
      return response;
    });
  }
  forkJoinAPI(data) {
    let listOfAPI = []
    data.forEach(element => {
      listOfAPI.push(this.http.post<any>(this.thirdPartyBaseURL + element.route, element.payload))
    });
    return forkJoin(listOfAPI);
  }

}
