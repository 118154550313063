import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import { ChartComponent } from "ng-apexcharts";
import {
  ApexNonAxisChartSeries, ApexResponsive, ApexChart, ApexFill, ApexDataLabels, ApexLegend, ApexAxisChartSeries, ApexPlotOptions, ApexXAxis,
  ApexStroke,
  ApexMarkers,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle
} from "ng-apexcharts";
import * as pluginLabels from "chartjs-plugin-labels";
import 'chartjs-plugin-labels';
import { environment } from 'src/environments/environment';
import _ from "lodash";

export type BarChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  responsive: ApexResponsive[];
  xaxis: ApexXAxis;
  legend: ApexLegend;
  fill: ApexFill;
};
export type LineChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  colors: string[];
  yaxis: ApexYAxis;
  grid: ApexGrid;
  legend: ApexLegend;
  title: ApexTitleSubtitle;
};
@Component({
  selector: 'app-charts',
  templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.scss']
})
export class ChartsComponent implements OnInit {
  @ViewChild("barChart") barChart: ChartComponent;
  public barChartOptions: Partial<BarChartOptions>;
  @ViewChild("lineChart") chart: ChartComponent;
  public lineChartOptions: Partial<LineChartOptions>;
  @Input() chartsData: any;
  public pieChartData: ChartDataSets[] = [];
  public pieChartPlugins = [];
  public pieChartOptions = {};
  public doughnutChartLabels = ['Waiting', 'Booking', 'Subsidy Released'];
  public doughnutChartData = [];
  physicalProgressChartOptions: any;
  barCategories: any = [];
  barOfflineData: any = [];
  barOnlineData: any = [];
  OnlineCam: number = 0;
  OfflineCam: number = 0;
  OnceliveCam: number = 0;
  hourList: any = [];
  hourCount: any = [];
  maxCount: number = 0;
  TotalCam: number = 0;
  barOnceLiveExcludeOnlineData: any = [];
  animate: boolean = true;
  dynamicData: any = {};
  minCount: number = 1;
  doughnutChartColorCode: any = [
    "gray",
    "#43978D",
    "#F9AD6A"]
  barChartColorCode: any = [

    "#43978D",
    "#F9AD6A",
    "gray",
  ]
  unifieddashboard: string = 'Y'
  constructor() {
    this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));
  }
  ngOnInit(): void {

  }
  ngOnChanges() {
    // console.log('this.chartsData.list.dataList', this.chartsData.list.TotalCam);
    localStorage.setItem('filterType', 'ALL');
    this.unifieddashboard = localStorage.getItem('unifieddashboard');
    this.dynamicData = JSON.parse(localStorage.getItem('projectConfig'));
    try {
      this.barCategories = [];
      this.barOfflineData = [];
      this.barOnceLiveExcludeOnlineData = [];
      this.barOnlineData = [];
      this.OnlineCam = this.chartsData.list.OnlineCam;
      this.OfflineCam = this.chartsData.list.OfflineCam;
      this.OnceliveCam = this.chartsData.list.OnceliveCam - this.chartsData.list.OnlineCam;
      this.TotalCam = this.chartsData.list.TotalCam;
      let sortedDistrictData = []
      debugger;
      if (environment.title == 'Puducherry') {
        let localData = this.chartsData.list.dataList
        sortedDistrictData = [...localData].sort((a, b) => a.District_ORDER - b.District_ORDER);
        sortedDistrictData.forEach(element => {
          this.barCategories.push(element.name);
          this.barOnlineData.push(element.OnlineCam);
          this.barOfflineData.push(element.OfflineCam);
          this.barOnceLiveExcludeOnlineData.push(element.OnceliveCam - element.OnlineCam)
        });
      } else {
        let localData = this.chartsData.list.dataList;
        let zeroCamEntries = localData.filter(item => item.TotalCam === 0);
        zeroCamEntries = _.sortBy(zeroCamEntries, ['Districtname'], ['asc'])
        let nonZeroCamEntries = localData.filter(item => item.TotalCam !== 0);
        nonZeroCamEntries = _.sortBy(nonZeroCamEntries, ['Districtname'],['asc'])
        const finalList = [...nonZeroCamEntries, ...zeroCamEntries];
       // this.cardList = finalList;
        nonZeroCamEntries.forEach(element => {
         // if (element.TotalCam != 0) {
            this.barCategories.push(element.name);
            this.barOnlineData.push(element.OnlineCam);
            this.barOfflineData.push(element.OfflineCam);
            this.barOnceLiveExcludeOnlineData.push(element.OnceliveCam - element.OnlineCam)
         // }
        });
        zeroCamEntries.forEach(element => {
         // if (element.TotalCam == 0) {
            this.barCategories.push(element.name);
            this.barOnlineData.push(element.OnlineCam);
            this.barOfflineData.push(element.OfflineCam);
            this.barOnceLiveExcludeOnlineData.push(element.OnceliveCam - element.OnlineCam)
         // }
        });
      }


      this.hourList = [];
      this.hourCount = [];
      const getHours = new Date().getHours() - 1;
      let isAllZero = true;
      let noZeroLastPosition = 0;

      if (this.chartsData.list.HourList.length > 0) {
        this.chartsData.list.HourList.forEach((element, i) => {
          if (isAllZero && element.OnlineCamera > 0)
            isAllZero = false;
          if (element.OnlineCamera > 0)
            noZeroLastPosition = i;
        });
      } else {
        this.maxCount = 0;
        this.hourList = [];
        this.hourCount = [];
      }

      if (noZeroLastPosition > 0 && !isAllZero) {
        this.chartsData.list.HourList.forEach((element, i) => {
          if (i < noZeroLastPosition) {
            this.hourList.push(element.Hour);
            this.hourCount.push(element.OnlineCamera);
          }
        });
        this.maxCount = Math.max(...this.hourCount);
        // this.minCount = Math.min(...this.hourCount);
      } else {
        this.chartsData.list.HourList.forEach((element, i) => {
          if (i < getHours) {
            this.hourList.push(element.Hour);
            this.hourCount.push(element.OnlineCamera = null);
          }
        });
        this.maxCount = 1000;
        this.minCount = 0;
      }

      this.loadCharts();
      this.doughnutChartjs(0);

    } catch (error) {

    }

  }

  getPercent(value) {
    const percent = (value * 100 / this.chartsData.list.TotalCam)
    return isNaN(percent) ? 0 : percent.toFixed(1)
  }

  doughnutChartjs(data) {
    this.doughnutChartData = [this.OfflineCam, this.OnlineCam, this.OnceliveCam]

    this.physicalProgressChartOptions = this.getDonutChartOptions(this.doughnutChartData, {})
    this.animate = false;
    this.pieChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      cutoutPercentage: 10,
      animation: {
        duration: 0,
      },
      legend: {
        position: 'top',
        align: "center",
        labels: {
          padding: 8
        }
      }
    }
    this.pieChartPlugins = [pluginLabels]
  }
  getDonutChartOptions(data: any, obj: any) {
    let _this = this;
    return {
      series: data,
      chart: {
        animations: {
          enabled: this.animate
        },
        events: {
          legendClick: function (chartContext, seriesIndex, config) {
            config.config.series.splice(seriesIndex, 1);
          },
        },
        type: "donut",
        // height: "100%", // Set height to 100%

        toolbar: {
          show: false,
        },
      },
      labels: ["Offline", "Online", "Once Live (Exclude Online)"],
      //   options: {
      //     aspectRatio: 2.5,
      // },

      colors: this.doughnutChartColorCode,

      stroke: {
        colors: ["rgba(0,0,0,0)"],
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        itemMargin: {
          horizontal: 8,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },


      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            columnWidth: "2%", // Adjust this value for smaller columns
            Height: '50%',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: 'Total \n Camera',
                color: '#000',
                formatter: function (w) {
                  const total = w.globals.seriesTotals.reduce((a, b) => {
                    return _this.TotalCam;
                  }, 0)
                  return total.toLocaleString('en-IN')
                }
              }
            }
          }
        }
      },

      dataLabels: {
        enabled: false,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "500",
          colors: ["#fff", "#fff", "#fff"],
        },
      },
    };
  }

  loadCharts() {


    this.barChartOptions = {

      series: [
        {
          name: "Online",
          data: this.barOnlineData
        },
        {
          name: "Once Live (Exclude Online)",
          data: this.barOnceLiveExcludeOnlineData
        },
        {
          name: "Offline",
          data: this.barOfflineData
        }
      ],
      chart: {
        animations: {
          enabled: this.animate
        },
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        fontFamily: 'Courier New , Courier, monospace',
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            animation: {
              duration: 0,
            },
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0
            }
          }
        }
      ],
      xaxis: {
        categories: this.barCategories
      },
      fill: {
        opacity: 1,
        /// "#DC8665","#138086","#534666"
        colors: this.barChartColorCode,

      },
      legend: {
        position: "bottom",
        offsetX: 0,
        offsetY: 0,
        fontFamily: 'Courier New , Courier, monospace',
        markers: {
          fillColors: this.barChartColorCode
        }
      },


    };



    this.lineChartOptions = {
      series: [
        {
          name: "Online Camera",
          data: this.hourCount
        }
      ],
      chart: {
        animations: {
          enabled: this.animate
        },
        height: 350,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },
        toolbar: {
          show: false
        }
      },
      colors: ["#43978D", "#545454"],
      dataLabels: {
        enabled: true
      },
      stroke: {
        curve: "smooth"
      },
      title: {
        text: "",
        align: "left"
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      markers: {
        size: 1
      },
      xaxis: {
        categories: this.hourList,
        title: {
          text: "Hours"
        },
        position: 'bottom',
        labels: {
          show: true,
          rotate: -65,
        }
      },
      yaxis: {
        title: {
          text: "Camera Counts"
        },
        min: 0,
        max: this.maxCount
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    };
  }
  statusByCam(filterType) {
    if (this.unifieddashboard == 'N') {
      localStorage.setItem('filterType', filterType);
    }

  }
}
