import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatTime',
  })
  export class FormatTimePipe implements PipeTransform {
    transform(value: number): string {
      const minutes: number = Math.floor(value / 60);
      return (
        ('00' + Math.floor(value - minutes * 60)).slice(-2)
      );
    }
  }
  @NgModule({
    declarations: [ FormatTimePipe ],
    exports: [ FormatTimePipe ]
  })
  
  export class FormatTimePipeModule {}