import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var require: any;
const version = require('package.json');

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  isDarkmode:boolean=false;
  currentYear = new Date().getFullYear();
  env = environment;
  evtTitle: string
  appVersion: any;

  constructor() { 
    let darkThemeclr: any = localStorage.getItem('isdarktheme')
    darkThemeclr = darkThemeclr == 'false' ? false : true;
   this.isDarkmode= darkThemeclr;
  }

  ngOnInit(): void {
    this.evtTitle = this.env.evtTitle;
    this.appVersion = version;
  }

}
