import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { DataService } from './data.service';
import { API_ENDPOINTS } from 'src/enum/api-endpoint';


@Injectable({ providedIn: 'root' })
export class DashboardService {
    getEventRoute = '/api/newevents/';
    evmDashboardFilters: { districtId: any, assemblyConstitutionId: any, transportCategoryId: any };
    dashboardType: number;
    dashboardFilters: { districtId: any, assemblyConstitutionId: any, transportCategoryId: any };


    constructor(private dataService: DataService) {
    };

    save(data: any) {
        return this.dataService.post('/api/newevents', data).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    saveNew(data: any) {
        return this.dataService.post('/api/schedule', data).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    getActualTarget(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/tripentry/targetactual/' + data.region + '/' + data.division + '/' + data.subdivision, refresh);
    }

    getSummaryDetails(refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/vehicle/dashboardsummary', refresh);
    }

    revenueData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/tripentry/revenue/' + data.region + '/' + data.division + '/' + data.subdivision + '/' + data.revenueFilterType, refresh);
    }
    // =============================================================Real Time Tracking Api================================//
    // state
    // getState(refresh: boolean) {
    //     // tslint:disable-next-line: max-line-length
    //     return this.dataService.getData('/api/lookup/state', refresh);
    // }


    getZoneBasedOnState(refresh: boolean) {
        return this.dataService.getData('/api/lookup/zone', refresh);
    }
    //District
    getDistrictBasedOnState(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/lookup/district/' + data, refresh);
    }
    getoOperatorMobileNoByDetails(mobileNo: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/user/Operator?mobileNo=' + mobileNo, refresh);
    }
    //Assembly
    getAssemblyBasedOnDistrict(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/lookup/assembly/' + data, refresh);
    }
    getpollingstation(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/lookup/pollingstation/' + data, refresh);
    }
    getpollingstationbysupervisors(data: any, refresh: boolean) {
        return this.dataService.getData('/api/lookup/pollingstationbyuser/' + data, refresh);
    }
    getpollingstationbyuser(data: any, refresh: boolean){
        return this.dataService.getData('/api/lookup/pollingstationbyuser/' + data, refresh);
    }
    getsupervisors(data: any, refresh: boolean) {
        return this.dataService.getData('/api/user/supervisors/' + data, refresh);
    }

    //Shifts

    getShifts(refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/lookup/shifts', refresh);
    }

    getProjectConfig(refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData(API_ENDPOINTS.ProjectConfig, refresh);
    }
    //transportID
    getTransportID(refresh: boolean) {
        return this.dataService.getData('/api/vehicle/transportcategory', refresh);
    }



    //Vehicle Tracking
    getDashboardDataLive(data) {
        return this.dataService.getDataWithParams('/api/vehicle/livelist', data, true);
    }

    getNotification() {
        return this.dataService.getData('/api/vehicle/notifications', true);
    }

    getSeizureData() {
        return this.dataService.getData('/api/seizeditem/MapData', true)
    }

    setDashboardFilters(filters) {
        this.dashboardType = 1
        this.dashboardFilters = filters
    }

    setEvmDashboardFilters(filters) {
        this.dashboardType = 2
        this.evmDashboardFilters = filters
    }
    savefence(data: any) {
        return this.dataService.post('/api/fence', data).map(response => {
            return response;
        });
    }
    getplaceofinterest(refresh: boolean) {
        return this.dataService.getData('/api/lookup/placeOfInterestType', refresh);
    }
    getfencelist(parliamentConstitutionId: any, assemblyConstitutionId: any, refresh: boolean) {
        return this.dataService.getData('/api/fence/' + parliamentConstitutionId + '/' + assemblyConstitutionId, refresh);
    }
    getFencingDatabyid(id: any, refresh: boolean) {
        return this.dataService.getData('/api/fence/' + id, refresh);
    }

    geofenceDelete(id: any, refresh) {
        return this.dataService.post('/api/fence/delete/' + id, refresh)
    }
    getconfig(key: any, refresh: boolean) {
        return this.dataService.getData('/api/config/info/' + key, refresh);
    }

    getRoleuserwise(refresh: boolean) {
        return this.dataService.getData('/api/lookup/userwiseroles', refresh)
    }

    getRole(refresh: boolean) {
        return this.dataService.getData('/api/lookup/roles', refresh)
    }


    getDashboardata(data: any, refresh: any) {
        return this.dataService.getData('/api/dashboard/' + data.stateId + '/' + data.zoneId + '/' + data.districtId + '/' + data.assemblyId + '/' + data.psId, refresh)
    }




    //operator mangament


    OperatorList(data: any, refresh: any) {
        return this.dataService.getData('/api/user/Operator/List/' + data.zoneId + '/' + data.districtId + '/' + data.assemblyId, refresh)
    }

    OperatorListById(operatorId: any, refresh: any) {
        return this.dataService.getData('/api/user/Operator/' + operatorId, refresh)
    }
    OperatorSave(data: any) {
        return this.dataService.post('/api/user/Operator/Save', data).map(response => {
            return response;
        });
    }
    OperatorUpdate(data: any) {
        return this.dataService.post('/api/user/Operator/Update', data).map(response => {
            return response;
        });
    }
    //////////////////// 
    getZoneIdByDistrictList(zoneId: any, refresh: boolean) {
        return this.dataService.getData(API_ENDPOINTS.ZoneIdByDistrictList + zoneId, refresh);
    }
    getDistrictIdByAssemblyList(DistrictId: any, refresh: boolean) {
        return this.dataService.getData(API_ENDPOINTS.DistrictIdByAssemblyList + DistrictId, refresh);
    }
    getAttendanceSummary(district_Id,attendanceDate,refresh: boolean) {
        return this.dataService.getData('/api/dashboard/AttendanceSummary/'+district_Id+'/'+attendanceDate, refresh);
    }
    getEnumList(moduletype: any, refresh: boolean) {
        return this.dataService.getData(API_ENDPOINTS.GetEnumList +'/'+ moduletype, refresh);
    }
    uploadValidate(data) {
        return this.dataService.post('/api/user/Operator/ImportExcelValidate', data).map(response => {
            return response;
        });

    }
    BulkUpdate(data) {
        return this.dataService.post('/api/user/Operator/Import', data).map(response => {
            return response;
        });

    }
    getSectorpollingstationlistAll(assemblyId,refresh){
        return this.dataService.getData(URL, refresh);
    }
    getEditSectorpollingstationlistAll(assemblyId,userInfoId,refresh){
        return this.dataService.getData('/api/lookup/pollingstationEdit/'+assemblyId+'/'+userInfoId, refresh);
    }
    UserUploadValidate(data) {
        return this.dataService.post('/api/user/Supervisor/ImportExcelValidate', data).map(response => {
            return response;
        });

    }
    dispatchCameraImportExcelValidate(data) {
        return this.dataService.post('/api/packingguru/DispatchCamera/ImportExcelValidate', data).map(response => {
            return response;
        });

    }
    UsrBulkUpdate(data) {
        return this.dataService.post('/api/user/Supervisor/Import', data).map(response => {
            return response;
        });

    }
    dispatchCameraImport(data) {
        return this.dataService.post('/api/packingguru/DispatchCamera/Import', data).map(response => {
            return response;
        });

    }
    configUpdate(data: any) {
        return this.dataService.post(API_ENDPOINTS.ConfigUpdate, data).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    syncData(URL, refresh: boolean) {
        return this.dataService.getData(URL, refresh);
    }

    getData(URL: any, refresh: boolean) {
        return this.dataService.getData(URL, refresh);
    }
    post(URL,data) {
        return this.dataService.post(URL, data).map(response => {
            return response;
        });
    }
    SupervisorList(data: any, refresh: any) {
        return this.dataService.getData('/api/user/Supervisor/List/' + data.zoneId + '/' + data.districtId + '/' + data.assemblyId, refresh)
    }
    SupervisorSave(data: any) {
        return this.dataService.post('/api/user/Supervisor/Save', data).map(response => {
            return response;
        });
    }
    SupervisorId(SupervisorId: any, refresh: any) {
        return this.dataService.getData('/api/user/Supervisor/' + SupervisorId, refresh)
    }
    complaint(data,refresh: any) {
        return this.dataService.getData('/api/Complaint/' +data.zoneId +'/'+data.districtId+'/'+data.assemblyId , refresh)
    }
    getReportvisit(data,refresh: any) {
        return this.dataService.getData('/api/notInstallated/' +data.zoneId +'/'+data.districtId+'/'+data.assemblyId , refresh)
    }
    Approve(data,refresh: any) {
        return this.dataService.post('/api/user/Supervisor/UpdateStatus' , data).map(response => {
            return response;
        });
    }
    CheckPostSyncdata(district_id,refresh: any)
    {
        return this.dataService.getData('/api/importPollingStationByDistrict/'+district_id, refresh)

    }
    SupervisorSupport(data,refresh: any)
    {
        return this.dataService.post('/api/ParliamentConstitution/UpdateContact' , data).map(response => {
            return response;
        });
    }
    installationApprove(data: any)
    {
        return this.dataService.post('/api/installation/Approve' , data).map(response => {
            return response;
        });
    }
    issueUpdate(data: any)
    {
        return this.dataService.post('/api/installation/IssueUpdate' , data).map(response => {
            return response;
        });
    }
    photoChangeRequest(data: any)
    {
        return this.dataService.post('/api/installation/PhotoChangeRequest' , data).map(response => {
            return response;
        });
    }
    Getsupervisor(refresh: any)
    {
        return this.dataService.getData('/api/ParliamentConstitution/ContactList', refresh)

    }
    getDataPacking(data,refresh: any)
    {
        return this.dataService.getData('/api/packingguru/GetDispatchedDeviceList/'+ data.districtId+'/'+data.assemblyId,refresh)

    }
    packingUpdate(data)
    {
        return this.dataService.post('/api/packingguru/UpdatePackage' , data).map(response => {
            return response;
        }); 
    }

}
