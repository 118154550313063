import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class RegionService {

    getEventRoute = '/api/region/';

    constructor(private dataService: DataService) {
    };

    getLookUp(refresh: boolean) {
        return this.dataService.getData('/api/region/lookup', refresh);
    }


    get(refresh: boolean) {
        return this.dataService.getData('/api/region', refresh);
    }

    getById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/region/' + id, refresh);
    }

    save(result: any) {
        return this.dataService.post('/api/region/', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    delete(id: number) {
        return this.dataService.delete('/api/region/' + id).map(response => {
            this.dataService.clearCache();
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }


}
