import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeahterIconModule } from 'src/app/core/feather-icon/feather-icon.module';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NotifyService } from 'src/app/services/notifyService';
import { NgApexchartsModule } from "ng-apexcharts";
import { MatSelectModule } from '@angular/material/select';
import { MatSelectFilterModule } from 'mat-select-filter';
import { NgxSelectModule } from 'ngx-select-ex';
import { ChartsModule } from 'ng2-charts';
import { ViewComponent } from './view/view.component';
import { SearchComponent } from './search/search.component';
import { ChartsComponent } from './charts/charts.component';
import { ViewDetailsComponent } from './view-details/view-details.component';
import { CardDetailsComponent } from './card-details/card-details.component';
import { TruncatePipe, TruncatePipeModule } from 'src/app/pipe/truncate.pipe';
import { SafePipe, SafePipeModule } from 'src/app/pipe/safe.pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FormatTimePipeModule } from 'src/app/pipe/formatTime.pipe';
import { FilterPipeModule } from 'src/app/pipe/filter.pipe';
import { UnifieddashboardComponent } from './unifieddashboard/unifieddashboard.component';

const routes: Routes = [
  {
    path: '',
    component: ViewComponent
  },
  {
    path: 'unifieddashboard',
    component: UnifieddashboardComponent
  }

]

@NgModule({
  declarations: [SearchComponent,ViewComponent, ChartsComponent, ViewDetailsComponent, CardDetailsComponent, UnifieddashboardComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    FeahterIconModule,
    NgbDropdownModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSelectFilterModule,
    NgxSelectModule,
    NgApexchartsModule,
    ChartsModule,
    NgbTooltipModule,
    MatSlideToggleModule ,
    SafePipeModule,
    FormatTimePipeModule,
    FilterPipeModule,
    TruncatePipeModule
  ],
  exports: [SearchComponent,ChartsComponent,ViewDetailsComponent,CardDetailsComponent],
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class SharingModule { }
