import { Component, OnInit } from '@angular/core';
import { ChartDataSets } from 'chart.js';
import * as pluginLabels from "chart.js";

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
  public pieChartData: ChartDataSets[] = [];
  public pieChartPlugins = [];
  public pieChartOptions = {};
  public doughnutChartLabels = ['Waiting', 'Booking', 'Subsidy Released'];
  public doughnutChartData = [];
  physicalProgressChartOptions: any;

  constructor() { }

  ngOnInit(): void {
   // alert('ViewComponent')
  }

  getPercent(value) {
    const percent = (value / 1 * 100)
    return isNaN(percent) ? 0 : percent.toFixed(1)
  }
  doughnutChartjs(data) {
    this.doughnutChartData = data

    this.physicalProgressChartOptions = this.getDonutChartOptions1(this.doughnutChartData, {})
    this.pieChartOptions = {
      responsive: true,
      maintainAspectRatio: true,
      cutoutPercentage: 20,
      legend: {
        position: 'top',
        align: "center",
        labels: {
          padding: 8
        }
      }
    }
    this.pieChartPlugins = [pluginLabels]
  }
  getDonutChartOptions1(data: any, obj: any) {
    return {
      series: data,
      chart: {
        events: {
          legendClick: function (chartContext, seriesIndex, config) {
            config.config.series.splice(seriesIndex, 1);
          },
        },
        type: "donut",
        background: "rgb(253, 251, 253)",
        height: 400,

        toolbar: {
          show: false,
        },
      },
      labels: ["Dairy", "Sheep and Goat", "Swine", "Rabbit", "Petanimals","Fodder production","Other innovative technologies","Technology for poultry"],
      colors: [
        "#f89a1c",
        "#f7a7d6",
        "#bca0dc",
        "#765295",

        "#93d09a",
        "#0c69a6",
        "#c6161d",
        "#c47575"


      ],
      stroke: {
        colors: ["rgba(0,0,0,0)"],
      },
      legend: {
        show: false,
        position: "bottom",
        horizontalAlign: "center",
        itemMargin: {
          horizontal: 8,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
      },


      plotOptions: {
        pie: {
          donut: {
            size: '65%',
            columnWidth: "25%",
            Height: '20%',
            labels: {
              show: true,
              total: {
                show: true,
                showAlways: true,
                label: 'Total',
                color: '#A9A9A9',
                formatter: function (w) {
                  const total = w.globals.seriesTotals.reduce((a, b) => {
                    return (a + b)
                  }, 0)
                  return total.toLocaleString('en-IN')
                }
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: "500",
          colors: ["#fff", "#fff", "#fff"],
        },
      },
    };
  }
}
